import React, { useEffect, useState } from 'react';
import GameButton from './GameButton';
import { tossUpIds } from '../../../analyticsTags';

function componseInterstitials(count, adIndex, adProps, props) {
  const data = [];
  for (let i = 0; i < count; i++) {
    const isAd = i === adIndex;
    const curr = isAd ? adProps : props;
    data.push(curr);
  }
  return data;
}

const Interstitial = ({
  count,
  props,
  adProps,
  adIndex,
  currentIndex,
  isCurrentView,
  onGameButtonClick,
  button: gameButton,
}) => {
  const [interstitialsData, setInterstitialsData] = useState([]);

  useEffect(() => {
    const dataDesk = componseInterstitials(count, adIndex, adProps, props);
    setInterstitialsData(dataDesk);
  }, [props, count, adProps, adIndex]);
  const isAd = currentIndex === adIndex;

  const currentData =
    interstitialsData.length > currentIndex
      ? interstitialsData[currentIndex]
      : null;

  // const button = currentData && currentData.button;

  return (
    currentData && (
      <div className={`interstitial hide-able${isCurrentView ? '' : ' hide'}`}>
        {/* Spacer pushes interstitial below game scoreboard */}
        <div className="interstitial_score_board_spacer"></div>
        {!isAd ? (
          <div className="interstitial_img">
            <img src={currentData.img} alt="img" />
            {/** TODO: Below is currently not configured for CMS */}
            {/* {button && (
                <a
                  style={button.style}
                  className={`core_btn btn_white interstitial-link ${button.class}`}
                  href={currentData.url}
                  target={currentData.target}
                  rel={
                    button.target === '_blank' ? 'noopener noreferrer' : null
                  }>
                  {button.text}
                </a>
              )} */}
          </div>
        ) : (
         <React.Fragment>
            <a
            className="interstitial_img ad"
            id={tossUpIds.tossUpInterstitial}
            href={currentData.url}
            target={currentData.target}
            rel={
              currentData.target === '_blank' ? 'noopener noreferrer' : null
            }>
            {<img src={currentData.img} alt="img" />}
            
          </a>
          <p className="adDesc">{(currentData.description) ? currentData.description : ""}</p>
         </React.Fragment>
        )}

        <GameButton
          button={gameButton}
          absolute={false}
          text="Next Round"
          onClick={onGameButtonClick}
        />
      </div>
    )
  );
};

export default Interstitial;
