import React, { useState } from 'react';

const TucButton = ({
  id,
  onClick,
  css,
  text,
  btnStyle,
  children,
  isCore = true,
}) => {
  const [hovered, setHover] = useState(false);

  const style = btnStyle
    ? {
        backgroundColor: hovered ? btnStyle.hoverBgColor : btnStyle.bgColor,
        borderColor: hovered ? btnStyle.hoverBgColor : btnStyle.bgColor,
        color: btnStyle.fontColor,
      }
    : {};

  return children || text ? (
    <button
      style={style}
      id={id}
      className={`no-focus-outline${isCore === true ? ' core_btn' : ''} ${css}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}>
      {children || text}
    </button>
  ) : null;
};

export default TucButton;
