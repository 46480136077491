import React from 'react';
import GameButton from './GameButton';

const Intro = ({
  introPath,
  isCurrentView,
  onGameButtonClick,
  introPlayNowText,
  commonDescription,
  button,
}) => {
  return (
    <div className={`intro hide-able${isCurrentView ? '' : ' hide'}`}>
      <div className="holder">
        {introPath && <img src={introPath} alt="img" />}
      </div>
      <div
        className="game-desc"
        dangerouslySetInnerHTML={{ __html: commonDescription }}></div>
      {introPlayNowText && (
        <GameButton
          button={button}
          text={introPlayNowText}
          onClick={onGameButtonClick}
        />
      )}
    </div>
  );
};

export default Intro;
