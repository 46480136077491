export const freeSpace = {
  id: 'free-space',
  img: require('./img/tiles/freespace.png'),
};

export const winImage = require('./img/winner-line.png');

const tilesGeneral = [
  {
    id: '1million',
    img: require('./img/tiles/1million.png'),
  },
  {
    id: 'fiftyk',
    img: require('./img/tiles/50k.png'),
  },
  {
    id: 'fivehundred',
    img: require('./img/tiles/500.png'),
  },
  {
    id: 'fivehundredfiftyfifty',
    img: require('./img/tiles/550.png'),
  },
  {
    id: 'sixhundredfifty',
    img: require('./img/tiles/650.png'),
  },
  {
    id: 'sixhundred',
    img: require('./img/tiles/600.png'),
  },
  {
    id: 'sevenhundred',
    img: require('./img/tiles/700.png'),
  },
  {
    id: 'eighthundred',
    img: require('./img/tiles/800.png'),
  },
  {
    id: 'ninehundred',
    img: require('./img/tiles/900.png'),
  },
  {
    id: 'twentyfivehundred',
    img: require('./img/tiles/2500.png'),
  },
  {
    id: 'thirtyfivehundred',
    img: require('./img/tiles/3500.png'),
  },
  {
    id: 'bankrupt',
    img: require('./img/tiles/bankrupt.png'),
  },
  {
    id: 'bonus',
    img: require('./img/tiles/bonus.png'),
  },
  {
    id: 'express',
    img: require('./img/tiles/express.png'),
  },
  {
    id: 'freeplay',
    img: require('./img/tiles/freeplay.png'),
  },
  {
    id: 'loseaturn',
    img: require('./img/tiles/loseaturn.png'),
  },
  {
    id: 'pat',
    img: require('./img/tiles/pat.png'),
  },
  {
    id: 'questionmark',
    img: require('./img/tiles/questionmark.png'),
  },
  {
    id: 'tossup',
    img: require('./img/tiles/tossup.png'),
  },
  {
    id: 'tripletossupv2',
    img: require('./img/tiles/tripletossupv2.png'),
  },
  {
    id: 'vanna',
    img: require('./img/tiles/vanna.png'),
  },
  {
    id: 'wild',
    img: require('./img/tiles/wild.png'),
  },
  {
    id: 'a',
    img: require('./img/tiles/a.png'),
  },
  {
    id: 'b',
    img: require('./img/tiles/b.png'),
  },
  {
    id: 'c',
    img: require('./img/tiles/c.png'),
  },
  {
    id: 'd',
    img: require('./img/tiles/d.png'),
  },
  {
    id: 'e',
    img: require('./img/tiles/e.png'),
  },
  {
    id: 'f',
    img: require('./img/tiles/f.png'),
  },
  {
    id: 'g',
    img: require('./img/tiles/g.png'),
  },
  {
    id: 'h',
    img: require('./img/tiles/h.png'),
  },
  {
    id: 'i',
    img: require('./img/tiles/i.png'),
  },
  {
    id: 'j',
    img: require('./img/tiles/j.png'),
  },
  {
    id: 'k',
    img: require('./img/tiles/k.png'),
  },
  {
    id: 'l',
    img: require('./img/tiles/l.png'),
  },
  {
    id: 'm',
    img: require('./img/tiles/m.png'),
  },
  {
    id: 'n',
    img: require('./img/tiles/n.png'),
  },
  {
    id: 'o',
    img: require('./img/tiles/o.png'),
  },
  {
    id: 'p',
    img: require('./img/tiles/p.png'),
  },
  {
    id: 'q',
    img: require('./img/tiles/q.png'),
  },
  {
    id: 'r',
    img: require('./img/tiles/r.png'),
  },
  {
    id: 's',
    img: require('./img/tiles/s.png'),
  },
  {
    id: 't',
    img: require('./img/tiles/t.png'),
  },
  {
    id: 'u',
    img: require('./img/tiles/u.png'),
  },
  {
    id: 'v',
    img: require('./img/tiles/v.png'),
  },
  {
    id: 'w',
    img: require('./img/tiles/w.png'),
  },
  {
    id: 'x',
    img: require('./img/tiles/x.png'),
  },
  {
    id: 'y',
    img: require('./img/tiles/y.png'),
  },
  {
    id: 'z',
    img: require('./img/tiles/z.png'),
  },
];

export const tilesCategories = [
  // {
  //   category: 'disney',
  //   required: 1,
  //   tiles: [
  //     {
  //       id: 'aulani',
  //       img: require('./img/tiles/aulani'),
  //     },
  //   ],
  // },
  {
    category: 'general',
    required: 0,
    tiles: tilesGeneral,
  },
];

export default tilesCategories;
