import React from 'react';
import { BannerAdType } from '../../types';
import { bannerAdIds } from '../../analyticsTags';
import './styles.scss';

const BannerAd: React.FC<BannerAdType> = ({ image, url }) => {
  return (
    <div className="game_banner">
      <a
        id={bannerAdIds.bannerAd}
        href={url}
        target="_blank"
        rel="noopener noreferrer">
        <img src={image} alt="ad" />
      </a>
    </div>
  );
};

export default BannerAd;
