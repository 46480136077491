import vars from '../scss/modules/_variables.scss';

const breakpoints = {
  lg: +vars.large_bp,
  md: +vars.medium_bp,
  tucMaxWidth: +vars.tuc_max_width,
};

function getTarget(external) {
  return external === true ? '_blank rel="noopener noreferrer"' : '_self';
}

const charMap = {
  65: 'a',
  66: 'b',
  67: 'c',
  68: 'd',
  69: 'e',
  70: 'f',
  71: 'g',
  72: 'h',
  73: 'i',
  74: 'j',
  75: 'k',
  76: 'l',
  77: 'm',
  78: 'n',
  79: 'o',
  80: 'p',
  81: 'q',
  82: 'r',
  83: 's',
  84: 't',
  85: 'u',
  86: 'v',
  87: 'w',
  88: 'x',
  89: 'y',
  90: 'z',
};

const keyCodeToChar = keyCode => charMap[keyCode] || '';

export { breakpoints, getTarget, keyCodeToChar };
