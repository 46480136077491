import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { analyticsPageLoad } from '../../analyticsTags';
import { AnalyticsPageLoadType } from '../../types';
import { RootState } from '../../reducers';

const AnalyticsPageLoad: React.FC<AnalyticsPageLoadType> = ({
  loginStatusChecked,
  isLoggedIn,
  contentTitle,
}) => {
  useEffect(() => {
    if (loginStatusChecked) {
      analyticsPageLoad(isLoggedIn, contentTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatusChecked]);

  return null;
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.auth.isLoggedIn,
  loginStatusChecked: state.auth.loginStatusChecked,
});

export default connect(mapStateToProps)(AnalyticsPageLoad);
