import $ from 'jquery';
import React from 'react';
import GameTop from './reactComponents/GameTop';
import GameBottom from './reactComponents/GameBottom';
import Intro from './reactComponents/Intro';
import Interstitial from './reactComponents/Interstitial';

import Audio from './code/js/audio';
import Game from './code/js/game';
import Keyboard from './code/js/keyboard';
import Wheel from './code/js/wheel';
import utils from './code/js/utils';

const Views = {
  intro: 'intro',
  game: 'play_now',
  interstitial: 'interstitial',
};

const initialView = Views.intro;

class TossUpChallenge extends React.PureComponent {
  state = {
    config: null,
    puzzles: null,
    startConfig: null,
    device: utils.device_detect(),
    howToPlayOpen: false,
    currentView: initialView,
    puzzleTimeTxt: '',
    audioOn: true,
    redAlert: false,
    initialized: false,
  };

  // non reactive jquery views
  audio = null;
  game = null;
  keyboard = null;
  wheel = null;

  // for non reactive jquery views
  loading = false;
  new = '';
  last = '';
  device = '';
  useMobileStyle = false;
  startConfig = null;

  handleResize = () => {
    const device = utils.device_detect();
    this.device = device;
    this.setState({ device });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener("focus", this.onFocus)
        window.addEventListener("blur", this.onBlur)
  }

  componentDidUpdate() {
    this.setGameData();
  }



onFocus = () => {
    if (this.current === 'play_now') this.game.unpause();
}
onBlur = () => {
      if (this.current === 'play_now') this.game.pause();
}

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener("blur", this.onBlur)
    window.addEventListener("focus", this.onFocus)
    // window.addEventListener('blur', onBlur);
    const startConfig = utils.get_data_for_site();
    this.device = utils.device_detect();
    this.audio = new Audio();
    this.keyboard = new Keyboard(this);
    this.game = new Game(this, this.audio, this.keyboard, startConfig);
    this.wheel = new Wheel(this, this.audio);
    this.setState({ startConfig, audioOn: !this.audio.muted }, () => {
      this.setGameData();
      // In case we navigate here without a user interaction there is a
      // catch clause to avoid console logs.
      // this.audio.play('wof_chant').catch(_e => {});
    });
  }

  setGameData = () => {
    const { config, puzzles } = this.props;
    const { initialized } = this.state;
    if (!initialized && puzzles && puzzles.length) {
      const {
        startConfig: { start_from_games },
      } = this.state;
      this.keyboard.initialize();
      this.game.initialize(config.game, puzzles);
      this.wheel.initialize(config.wheel);

      this.setState(
        {
          config,
          puzzles,
          currentView: start_from_games ? Views.game : initialView,
          initialized: true,
        },
        () => {
          if (start_from_games) {
            // games is not reactive and will not open itself if it is the
            // first component to render.
            this.open(Views.game);
          }
        },
      );
    }
  };

  updateTime = puzzleTimeTxt => this.setState({ puzzleTimeTxt });

  open = val => {
    if (!this.loading) {
      this.new = val;
      this.loading = true;
      this.close(val);
      return;
    }

    if (val === 'intro') {
    } else if (val === 'play_now') {
      this.game.open();
    } else if (val === 'interstitial') {
    } else if (val === 'wheel') {
      this.wheel.open();
    }

    this.loading = false;
    this.last = this.current;
    this.current = this.new;
    this.setState({ currentView: this.current });
  };

  close = val => {
    if (this.current === 'intro') {
    } else if (this.current === 'play_now') {
      this.game.close();
    } else if (this.current === 'interstitial') {
    } else if (this.current === 'wheel') {
      this.wheel.close();
    }

    this.open(val);
  };

  closeHtp = () => {
    if (this.current === 'play_now') this.game.unpause();
    this.toggleHtp(false);
  };

  openHtp = () => {
    if (this.state.howToPlayOpen) {
      this.closeHtp();
      return;
    }
    if (this.current === 'play_now') this.game.pause();
    this.toggleHtp(true);
  };

  toggleHtp = howToPlayOpen => this.setState({ howToPlayOpen });

  setAudio = () => {
    // non reactive audio update
    this.audio.toggleMute(this.state.audioOn);
    // reactive state update, audio_on updated in above call.
    this.setState({ audioOn: !this.audio.muted });
  };

  setRedAlert = redAlert => this.setState({ redAlert });
  getRedAlert = redAlert => {return this.state.redAlert};

  onGameButtonClick = () => {
    // eslint-disable-next-line default-case
 
    switch (this.state.currentView) {
      case Views.intro:
        this.open(Views.game);
        break;
      case Views.interstitial:
        this.game.next_round();
        utils.track({
          event: 'Game',
          gameAction: 'Next Round',
          gameTitle: 'WOF_Toss_Up',
          gameResult: '',
        });
        break;
    }
  };
  render() {
    const {
      currentView,
      howToPlayOpen,
      device,
      puzzleTimeTxt,
      audioOn,
      redAlert,
      config,
      initialized,
    } = this.state;
    const htpProps = {
      onClose: this.closeHtp,
      htpCloseClass:
        (config && config.howToPlay && config.howToPlay.htpCloseClass) || '',
      htpCloseText:
        (config && config.howToPlay && config.howToPlay.htpCloseText) || '',
      isOpen: howToPlayOpen,
      button: (config && config.howToPlay && config.howToPlay.button) || {},
    };

    const gameConfig = config || {};
    const background = gameConfig.common
      ? gameConfig.common.backgroundPath
      : '';

    return (
      <div
        id="toss_up_container"
        className={`wof-react-component ${device}`}
        style={{ backgroundImage: 'url(' + background + ')' }}>
        <div id="game_holder">
          <GameTop
            {...(gameConfig.common || {})}
            audioProps={gameConfig.audio || {}}
            audioOn={audioOn}
            puzzleTimeTxt={puzzleTimeTxt}
            gameInProgress={currentView !== Views.intro}
            redAlert={redAlert}
            onAudioClick={this.setAudio}
            onHowToPlayClicked={this.openHtp}
          />
          <div className="game_holder_aspect">
            <div className="game_holder_container">
              <GameBottom htpProps={htpProps}>
                <Intro
                  {...(gameConfig.intro || {})}
                  isCurrentView={currentView === Views.intro}
                  onGameButtonClick={this.onGameButtonClick}
                />
                {initialized && (
                  <Interstitial
                    {...(gameConfig.interstitial || {})}
                    currentIndex={this.game ? this.game.round - 1 : 0}
                    adIndex={3}
                    count={4}
                    isCurrentView={currentView === Views.interstitial}
                    onGameButtonClick={this.onGameButtonClick}
                  />
                )}
              </GameBottom>
            </div>
          </div>
        </div>
      </div>
    );
  }

  width() {
    let value = utils.max_width * utils.scale();
    if (this.state.device !== 'desktop') value = utils.window_width();
    return value;
  }

  div_display(id, display) {
    $(id).css({
      display: display,
    });
  }

  div_remove(id) {
    utils.trace('div_remove id = ' + id);
    $(id).remove();
  }
}

export default TossUpChallenge;
