import React from 'react';
import TucButton from './TucButton';
import { tossUpIds } from '../../../analyticsTags';

const GameButton = ({ absolute, text, onClick, button }) => {
  return (
    <div className={`game_button_area${absolute ? ' absolute' : ''}`}>
      <div className="game_button_holder">
        <TucButton
          id={tossUpIds.tossUpGameButton}
          btnStyle={button}
          css="white_btn game_button"
          onClick={onClick}
          text={text}
        />
      </div>
    </div>
  );
};

export default GameButton;
