import React from "react";
import { google_ad_unitname } from "../../types";

import "./styles.scss";
import { useEffect } from "react";

declare global {
  interface Window {
    googletag: any;
  }
}
const useDfpSlot = (path: any, size: any, id: any) => {
  useEffect(() => {
    const googletag = window.googletag;
    if (
      googletag &&
      googletag.defineSlot &&
      googletag.addService &&
      typeof googletag != "undefined"
    ) {
      let mySizeMapping = googletag
        .sizeMapping()
        .addSize([1, 1], [320, 50])
        .addSize([576, 0], [300, 250])
        .addSize([1024, 0], size)
        .build();

      googletag.cmd = googletag.cmd || [];
      googletag.cmd.push(function() {
        googletag
          .defineSlot(path, size, id)
          .addService(googletag.pubads())
          .defineSizeMapping(mySizeMapping);

        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
      });
      googletag.cmd.push(function() {
        googletag.display(id);
      });
    }
  }, [path, size, id]);
};

const GoogleAd: React.FC<any> = ({ ad }) => {
  var ad_size = [
    ad.width ? parseInt(ad.width) : 728,
    ad.height ? parseInt(ad.height) : 90,
  ];
  useDfpSlot(google_ad_unitname, ad_size, "div-gpt-ad-wof_com-43");

  return (
    <div style={{ textAlign: "center", width: "auto", padding: "30px 0" }}>
      <div style={{ display: "inline-block" }} id="div-gpt-ad-wof_com-43" />
    </div>
  );
};

export default GoogleAd;
