import $ from 'jquery';
import TweenMax from '../gsap/TweenMax';
import utils from './utils';

class Keyboard {
  id = 'keyboard';
  active = false;
  data = [];
  current = 0;
  built = false;
  site = null;

  constructor(site) {
    this.site = site;
  }

  initialize() {
    var thisobj = this;

    this.data = [
      'q',
      'w',
      'e',
      'r',
      't',
      'y',
      'u',
      'i',
      'o',
      'p',
      '',
      'a',
      's',
      'd',
      'f',
      'g',
      'h',
      'j',
      'k',
      'l',
      '',
      'z',
      'x',
      'c',
      'v',
      'b',
      'n',
      'm',
      'submit',
    ];

    this.render();

    $(window).resize(function() {
      thisobj.resize();
    });
  }

  render() {
    // var thisobj = this;
    var row = 0;

    utils.trace(this.id + ' render');

    $('#game_bottom_holder').append('<div id="' + this.id + '"></div>');
    $('#' + this.id).css({
      position: 'fixed',
      'background-color': 'rgba(0,0,0,0.5)',
      display: 'none',
      'z-index': '1000',
    });

    TweenMax.set($('#' + this.id), { opacity: 0 });

    $('#' + this.id).append('<div id="' + this.id + '_row_0"></div>');
    $('#' + this.id + '_row_0').css({
      display: 'table',
      margin: '0 auto',
    });

    $('#' + this.id).append('<div id="' + this.id + '_row_1"></div>');
    $('#' + this.id + '_row_1').css({
      display: 'table',
      margin: '0 auto',
    });

    $('#' + this.id).append('<div id="' + this.id + '_row_2"></div>');
    $('#' + this.id + '_row_2').css({
      display: 'table',
      margin: '0 auto',
    });

    var i;
    const site = this.site;

    for (i = 0; i < this.data.length; i++) {
      if (this.data[i] !== '') {
        $('#' + this.id + '_row_' + row).append(
          '<div id="' +
            this.id +
            '_' +
            this.data[i] +
            '_btn" btnid="' +
            this.data[i] +
            '" class="' +
            this.id +
            '_btn">' +
            this.data[i] +
            '</div>',
        );
        $('.' + this.id + '_btn').css({
          display: 'inline-block',
          'background-image': 'url(' + utils.getImage('btn_gradient.png') + ')',
          'background-repeat': 'x-repeat',
          'background-color': '#00a0f3',
          'background-position': 'top left',
          'background-size': 'auto 100%',
          'font-family': 'Lato',
          'font-weight': '300',
          cursor: 'pointer',
          'text-align': 'center',
          color: '#FFF',
          'text-transform': 'uppercase',
        });

        $('#' + this.id + '_' + this.data[i] + '_btn').click(function(event) {
          var id = $(this).attr('btnid');
          site.game.letter_selected(id);
        });
      } else {
        row = row + 1;
      }
    }

    this.resize();

    //this.open();
  }

  open() {
    if (this.active) return;

    utils.trace(this.id + ' ---------------  open');

    this.active = true;

    TweenMax.to($('#' + this.id), 0.5, {
      opacity: 1,
      onStart: this.site.div_display,
      onStartParams: ['#' + this.id, 'block'],
      onStartScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
  }

  close() {
    utils.trace(this.id + ' --------------------- close');
    this.active = false;
    $('#' + this.id + '_img').css({ rotation: 0 });
    TweenMax.to($('#' + this.id), 0.5, {
      opacity: 0,
      onComplete: this.site.div_display,
      onCompleteParams: ['#' + this.id, 'none'],
      onCompleteScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
  }

  resize() {
    $('#' + this.id).css({
      width: this.width() + 'px',
      //height: this.height() + 'px',
      // top: this.top() + 'px',
      left: this.left() + 'px',
      padding: '2% 0',
      bottom: '0px',
    });

    $('.' + this.id + '_btn').css({
      'font-size': this.btn_size() + 'px',
      'line-height': this.btn_leading() + 'px',
      padding: '0px ' + this.btn_p() + 'px',
      margin: this.btn_m() + 'px',
    });

    TweenMax.set($('.' + this.id + '_btn'), {
      borderRadius: this.btn_corner() + 'px',
    });
  }

  isMobile() {
    return this.site.device !== 'desktop';
  }

  btn_m() {
    var value = 7 * utils.scale();
    if (this.isMobile()) value = utils.window_width() * 0.008;
    return value;
  }

  btn_p() {
    var value = 7 * utils.scale();
    if (this.isMobile()) value = utils.window_width() * 0.02;
    return value;
  }

  btn_corner() {
    var value = 7 * utils.scale();
    if (this.isMobile()) value = utils.window_width() * 0.02;
    return value;
  }

  btn_size() {
    var value = 18 * utils.scale();
    if (this.isMobile()) value = utils.window_width() * 0.06;
    return value;
  }

  btn_leading() {
    var value = 36 * utils.scale();
    if (this.isMobile()) value = utils.window_width() * 0.075;
    return value;
  }

  left() {
    var value = 0 * utils.scale();
    if (this.isMobile()) value = utils.window_width() * 0.0;
    return value;
  }

  width() {
    var value = utils.max_width * utils.scale();
    if (this.isMobile()) value = utils.window_width() * 1;
    return value;
  }

  height() {
    var value = utils.max_width * utils.scale();
    if (this.isMobile()) value = utils.window_width() * 0.3;
    return value;
  }

  // btn_t() {
  //   var value = this.data[this.current].t * utils.scale() + this.top();
  //   if (this.isMobile()) value = utils.window_width() * 1;
  //   return value;
  // }

  // btn_l() {
  //   var value = this.data[this.current].l * utils.scale() + this.left();
  //   if (this.isMobile()) value = utils.window_width() * 1;
  //   return value;
  // }

  // btn_w() {
  //   var value = this.data[this.current].w * utils.scale();
  //   if (this.isMobile()) value = utils.window_width() * 1;
  //   return value;
  // }

  // next_round_btn_w() {
  //   var value = 285 * utils.scale();
  //   if (this.isMobile()) value = utils.window_width() * 0.3;
  //   return value;
  // }

  // btn_holer_tb() {
  //   var value = 25 * utils.scale();
  //   if (this.isMobile()) value = utils.window_width() * 0.02;
  //   return value;
  // }

  // btns_h() {
  //   var value = 86 * utils.scale();
  //   if (this.isMobile()) value = utils.window_width() * 0.2;
  //   return value;
  // }

  // img_wh() {
  //   var value = 961 * utils.scale();
  //   if (this.isMobile()) value = utils.window_width() * 1;
  //   return value;
  // }

  // img_t() {
  //   var value = 70 * utils.scale();
  //   if (this.isMobile()) value = utils.window_width() * 0;
  //   return value;
  // }

  // img_l() {
  //   var value = 0 * utils.scale();
  //   if (this.isMobile()) value = utils.window_width() * 0;
  //   return value;
  // }

  // top() {
  //   var value = 0 * utils.scale();
  //   if (this.isMobile())
  //     value =
  //       utils.window_width() * 0.32 * ADJUSTMENT_CONSTS.MOBILE_WIDTH_SCALE;
  //   return value;
  // }
}

export default Keyboard;
