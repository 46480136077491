import $ from 'jquery';
import TweenMax from '../gsap/TweenMax';
import utils, { ADJUSTMENT_CONSTS, PUZZLE_KEY, REACT_PATH } from './utils';
import { tossUpIds } from '../../../../analyticsTags';

const MAX_WIDTH = 961;
const RAW_GRID_IMG_WIDTH = 861;
const RAW_GRID_IMG_HEIGHT = 342;
const SCALE = 861.0 / RAW_GRID_IMG_WIDTH;
const GRID_IMG_WIDTH = SCALE * RAW_GRID_IMG_WIDTH;
const GRID_IMG_HEIGHT = SCALE * RAW_GRID_IMG_HEIGHT;
const GRID_LEFT = (MAX_WIDTH - GRID_IMG_WIDTH) / 2;
const GRID_TOP = 25;
const GRID_PADDING = SCALE * 41.25;
const GRID_SPACING = SCALE * 56.8;
const LETTER_W = SCALE * 41;
const LETTER_H = (SCALE * 55) / 41;
const START_Y_1 = SCALE * 39;
const START_Y_2 = SCALE * 108;
const START_Y_3 = SCALE * 179;
const START_Y_4 = SCALE * 249;
const START_X_14 = GRID_PADDING;
const START_X_12 = START_X_14 + GRID_SPACING;

class Game {
  id = 'game';
  active = false;
  puzzles = [];
  current_puzzle = -1;
  puzzle_txt = [];
  round = 1;
  puzzle = 1;
  score = 0;
  grid = [];
  time = 0;
  total_charc = 0;
  reveal_time = 1.5;
  reveal_sequence = [];
  letters = [];
  game_playing = false;
  solving = false;
  submitted = false;
  user_score = 0;
  puzzle_score = 0;

  alert_clr = '#fff';
  alert_back_clr = '#d0112b';
  bonus = false;
  current_btn = '';
  bonus_ad = null;
  bonus_ad_url = 'https = //www.wheeloffortune.com/account/Register/';
  selected_letter = -1;
  last_letter = -1;
  react_puzzle_id = null;
  startConfig = null;
  audio = null;
  keyboard = null;
  config = null;

  constructor(site, audio, keyboard, startConfig) {
    this.site = site;
    this.audio = audio;
    this.keyboard = keyboard;
    this.startConfig = startConfig;
  }

  initialize(config, puzzles) {
    this.config = config;
    this.bonus_ad = config.gameBonusAdPath;
    var thisobj = this;
    this.initialize_score();
    this.initialize_puzzles(puzzles);

    $(window).resize(function() {
      thisobj.resize();
    });

    this.render();
  }

  initialize_score() {
    if (this.startConfig.puzzle_start_score !== null) {
      utils.trace(`Starting on score: ${this.startConfig.puzzle_start_score}`);
      this.puzzle_score = this.startConfig.puzzle_start_score;
      this.set_score(this.puzzle_score);
    }
  }

  initialize_puzzles(data) {
    // Add original index so we know which puzzle we already played on the react side.
    // This assumes they always come in the same order from the cms.
    // If not, then the cms needs to add something like an 'id' field.
    this.data = Array.isArray(data)
      ? data.map((d, i) => ({ ...d, [PUZZLE_KEY]: i }))
      : [];

    this.build_puzzles();

    if (this.startConfig.puzzle_start_index !== null) {
      utils.trace(`Starting on puzzle: ${this.startConfig.puzzle_start_index}`);
      const removePuzzleIndex = this.data.findIndex(
        p => p[PUZZLE_KEY] === this.startConfig.puzzle_start_index,
      );
      if (removePuzzleIndex > -1) {
        this.puzzles.splice(removePuzzleIndex, 1);
        this.puzzle++;
      }
    }
  }

  build_puzzles() {
    this.puzzles = this.data.map((_, i) => i);
  }

  render() {
    var thisobj = this;

    utils.trace(this.id + ' render');

    $('body').keydown(function(e) {
      // Filter to trigger the koydown only while solving the puzzle
      if (!thisobj.solving || $(':focus').is('input, textarea')) {
        return;
      }

      utils.trace(e.keyCode);
      if (e.keyCode === 8) {
        e.preventDefault();
      } else {
      }
      thisobj.key_pressed(e.keyCode);
    });

    $('#' + this.id + '_bottom_holder').append(
      '<div id="' + this.id + '"></div>',
    );
    $('#' + this.id).css({
      position: 'absolute',
      top: '0px',
      left: '0px',
      display: 'none',
      overflow: 'hidden',
      'flex-direction': 'column',
    });
    TweenMax.set($('#' + this.id), { opacity: 0 });

    $('#' + this.id).append('<div id="' + this.id + '_score_board"></div>');
    $('#' + this.id + '_score_board').css({
      float: 'left',
      width: '100%',
      'background-color': 'rgba(0,0,0,.5)',
    });

    $('#' + this.id + '_score_board').append(
      '<div id="' + this.id + '_round" class="score_unit"></div>',
    );
    $('#' + this.id + '_round').append(
      '<div class="score_title">ROUND&nbsp;:&nbsp;</div>',
    );
    $('#' + this.id + '_round').append(
      '<div id="round_val" class="score_val">1</div>',
    );

    $('#' + this.id + '_score_board').append(
      '<div id="' + this.id + '_puzzle" class="score_unit"></div>',
    );
    $('#' + this.id + '_puzzle').append(
      '<div class="score_title">PUZZLE&nbsp;:&nbsp;</div>',
    );
    $('#' + this.id + '_puzzle').append(
      '<div id="puzzle_val" class="score_val">1</div>',
    );

    $('#' + this.id + '_score_board').append(
      '<div id="' + this.id + '_score" class="score_unit"></div>',
    );
    $('#' + this.id + '_score').append(
      '<div class="score_title">SCORE&nbsp;:&nbsp;</div>',
    );
    $('#' + this.id + '_score').append(
      `<div id="score_val" class="score_val">${thisobj.user_score}</div>`,
    );

    $('#' + this.id + '_score_board').append(
      '<div id="' + this.id + '_timer">00:00</div>',
    );

    $('#' + this.id + '_timer').css({
      float: 'right',
      'background-color': '#FFF',
      color: '#000',
      'text-align': 'center',
      'font-weight': '600',
    });

    $('#' + this.id).append('<div id="' + this.id + '_grid"></div>');

    $('#' + this.id + '_grid').css({
      float: 'left',
      width: '100%',
    });

    $('#' + this.id + '_grid').append(
      '<div id="' + this.id + '_grid_holder"></div>',
    );

    $('#' + this.id + '_grid_holder').css({
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      'flex-direction': 'column',
    });

    $('#' + this.id + '_grid_holder').append(
      '<div id="' +
        this.id +
        '_grid_img"><img src="' +
        this.config.gameGrid +
        '"></div>',
    );

    $('#' + this.id + '_grid_img').css({
      position: 'relative',
    });

    $('#' + this.id + '_grid_holder').append(
      '<div id="' + this.id + '_grid_letters"></div>',
    );

    $('#' + this.id + '_grid_letters').css({
      position: 'absolute',
    });

    $('#' + this.id + '_grid_holder').append(
      '<div id="' + this.id + '_hint_holder"></div>',
    );
    $('#' + this.id + '_hint_holder').css({
      display: 'flex',
      'align-items': 'center',
      flex: '1',
      'justify-content': 'center',
    });
    $('#' + this.id + '_hint_holder').append(
      '<div id="' + this.id + '_hint"></div>',
    );
    $('#' + this.id + '_hint').css({
      position: 'relative',
      'background-image': 'url(' + this.config.hintBackground + ')',
      'background-position': 'center top',
      'background-size': 'auto 100%',
      'font-weight': '600',
      color: '#FFF',
      'text-align': 'center',
      'text-transform': 'uppercase',
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
    });

    $('#' + this.id + '_grid_holder').append(
      '<div id="' + this.id + '_btns"></div>',
    );
    $('#' + this.id + '_btns').css({
      'background-color': 'rgba(0,0,0,0.5)',
      position: 'relative',
      bottom: '0px',
    });

    $('#' + this.id + '_btns').append(
      '<div id="' + this.id + '_btn_holder"></div>',
    );

    this.renderGameButton(
      'solve',
      this.config.gameSolveClass,
      this.config.gameSolveText,
    );
    this.renderGameButton(
      'submit',
      this.config.gameSubmitClass,
      this.config.gameSubmitText,
    );
    this.renderGameButton(
      'next_puzzle',
      this.config.gameNextClass,
      this.config.gameNextText,
    );
    this.renderGameButton(
      'try_again',
      this.config.gameTryAgainClass,
      this.config.gameTryAgainText,
    );
    this.renderGameButton(
      'spin_for_points',
      this.config.gameSpinClass,
      this.config.gameSpinText,
    );
    this.renderGameButton(
      'play_again',
      this.config.gamePlayAgainClass,
      this.config.gamePlayAgainText,
    );
    this.renderGameButton(
      'play_again_lose',
      this.config.gamePlayAgainClass,
      this.config.gamePlayAgainText,
    );

    $('.solve_btn').click(function(event) {
      if (!thisobj.game_playing) return;
      thisobj.audio.play('clock');
      thisobj.solve();
    });

    $('.submit_btn').click(function(event) {
      thisobj.submit();
    });

    $('.next_puzzle_btn').click(function(event) {
      thisobj.check_navigate_to_react();
      thisobj.next_puzzle();
      utils.track({
        event: 'Game',
        gameAction: 'Next Puzzle',
        gameTitle: 'WOF_Toss_Up',
        gameResult: '',
      });
    });

    $('.try_again_btn').click(function(event) {
      thisobj.try_again();
    });

    $('.spin_for_points_btn').click(function(event) {
      thisobj.site.open('wheel');

      utils.track({
        event: 'Game',
        gameAction: 'Spin For Points',
        gameTitle: 'WOF_Toss_Up',
        gameResult: '',
      });
    });

    $('.play_again_btn').click(function(event) {
      thisobj.play_again();
    });

    $('.play_again_lose_btn').click(function(event) {
      thisobj.play_again();
    });
    this.set_timer(0);
    this.build_grid();
    this.resize();
    this.preload_letters();
  }

  renderGameButton(btnid, css, text) {
    this.renderButton(this.id, btnid, css, text);
  }

  renderButton(componentId, btnid, css, text, show = false) {
    const { fontColor, bgColor, hoverBgColor } = this.config.button || {};

    $('#' + componentId + '_btn_holder').append(
      `<button id="${tossUpIds.tossUpGameButton}" btnid="${btnid}" class="${btnid}_btn core_btn action_btn ${css}">${text}</button>`,
    );

    $(`.${btnid}_btn`)
      .css({
        display: show ? 'inline-block' : 'none',
      })
      .addClass('no-focus-outline');

    if (bgColor && hoverBgColor) {
      $(`.${btnid}_btn`)
        .css({
          color: fontColor,
          'background-color': bgColor,
          'border-color': bgColor,
        })
        .on('mouseenter', function() {
          $(this).css({
            'background-color': hoverBgColor,
            'border-color': hoverBgColor,
          });
        })
        .on('mouseleave', function() {
          $(this).css({ 'background-color': bgColor, 'border-color': bgColor });
        });
    }
  }

  build_grid() {
    var startx = START_X_12;
    var starty = START_Y_1;
    var space = GRID_SPACING;

    var i;

    for (i = 0; i < 12; i++) {
      this.grid.push({ x: startx, y: starty });
      startx = startx + space;
    }

    startx = START_X_14;
    starty = START_Y_2;

    for (i = 0; i < 14; i++) {
      this.grid.push({ x: startx, y: starty });
      startx = startx + space;
    }

    startx = START_X_14;
    starty = START_Y_3;

    for (i = 0; i < 14; i++) {
      this.grid.push({ x: startx, y: starty });
      startx = startx + space;
    }

    startx = START_X_12;
    starty = START_Y_4;

    for (i = 0; i < 12; i++) {
      this.grid.push({ x: startx, y: starty });
      startx = startx + space;
    }

    for (i = 0; i < this.grid.length; i++) {}

    this.resize();
  }

  getLetterImg(letter) {
    return require(`../images/letters/${letter}.jpg`);
  }

  get_puzzle() {
    utils.trace('get_puzzle');

    var total = this.puzzles.length - 1;
    var new_puzzle = Math.floor(Math.random() * total);

    this.current_puzzle = this.puzzles[new_puzzle];
    this.set_puzzle();
    this.puzzles.splice(new_puzzle, 1);
  }

  set_puzzle() {
    utils.trace('utils.puzzle_override = ' + utils.puzzle_override);
    if (utils.puzzle_override !== null) {
      this.current_puzzle = utils.puzzle_override;
    }

    //utils.trace("set_puzzle this.current_puzzle = "+this.current_puzzle);
    TweenMax.killAll(false, false, true, false);

    const getRowData = row => (row ? row.toLowerCase() : '');
    const currentPuzzle = this.data[this.current_puzzle];

    var hint = currentPuzzle.hint;
    var row1 = this.add_spaces(getRowData(currentPuzzle.row1), 12);
    var row2 = this.add_spaces(getRowData(currentPuzzle.row2), 14);
    var row3 = this.add_spaces(getRowData(currentPuzzle.row3), 14);
    var row4 = this.add_spaces(getRowData(currentPuzzle.row4), 12);

    this.puzzle_txt = row1 + row2 + row3 + row4;

    //utils.trace("this.puzzle_txt = "+this.puzzle_txt)

    this.total_charc = 0;
    this.letters = [];

    //utils.trace("this.round = "+this.round )
    var puzzle_txt = this.puzzle;
    var puzzle_txt_clr = '#FFF';
    if (this.round > 3) {
      puzzle_txt = 'BONUS';
      this.bonus = true;
      puzzle_txt_clr = '#ffff00';
    }
    $('#round_val').html(this.round);
    $('#puzzle_val').html(puzzle_txt);

    $('#puzzle_val').css({ color: puzzle_txt_clr });

    $('#' + this.id + '_hint').html(hint);

    $('#' + this.id + '_grid_letters').html('');

    var i;

    for (i = 0; i < this.grid.length; i++) {
      this.grid[i].letter = this.puzzle_txt.charAt(i);

      this.grid[i].user_letter = ' ';
      this.grid[i].revealed = false;

      if (this.grid[i].letter === '/') {
        this.grid[i].letter = ' ';
        this.grid[i].revealed = true;
      }

      if (this.grid[i].letter !== ' ') {
        var letter = 'space';
        if (this.grid[i].letter === '-') letter = 'dash';
        if (this.grid[i].letter === '&') letter = 'and';
        if (this.grid[i].letter === "'") letter = 'apos';
        if (this.grid[i].letter === '!') letter = 'exclaim';
        if (this.grid[i].letter === '?') letter = 'quest';
        if (this.grid[i].letter === '.') letter = 'period';

        if (letter !== 'space') this.grid[i].revealed = true;
        var spacer_img = this.getLetterImg(letter);
        $('#' + this.id + '_grid_letters').append(
          '<div id="grid_' +
            i +
            '" btnid="' +
            i +
            '" class="letter"><img src="' +
            spacer_img +
            '"></div>',
        );

        $('#grid_' + i).css({
          position: 'absolute',
          left: this.grid[i].x * this.scale() + 'px',
          top: this.grid[i].y * this.scale() + 'px',
        });

        $('#grid_' + i + ' img').css({
          width: '100%',
          height: 'auto',
        });
        this.total_charc++;
        this.letters.push(i);
      }
    }

    this.site.setRedAlert(false);
    if (this.alert_back_clr === '#d0112b') this.red_alert();
    this.resize();
   

    // this is the first time in the game where we can take advantage of a user interaction
    // to initialize the sounds.
    this.audio.initializeOnUserInteraction(() => {
      // This sound plays the puzzle reveal for 2 seconds, then the toss up sound.
      // Wait for the sound to start playing using the promise so it is synced
      // with the 2 second timer better.
      
      // this.audio.play('puzzle_reveal_and_toss').finally(() => {
      //   TweenMax.delayedCall(2, this.start, [], this);
      // });

       this.audio.play('puzzle_reveal_and_toss').then(() => {
        TweenMax.delayedCall(2, this.start, [], this);
      }).catch((e)=>{
        TweenMax.delayedCall(2, this.start, [], this);
      }); 
    });
    // TweenMax.delayedCall(2, this.start, [], this);
  }

  start() {
    utils.trace(this.id + ' start ');

    // this.audio.play('toss_up').then(() => {
    this.game_playing = true;

    this.time = Math.round((this.total_charc) * this.reveal_time);
    this.set_timer(this.time);
    this.seconds();
    this.get_reveal();
     this.btn_open('solve_btn');
    // });
  }

  pause() {
    this.game_playing = false;
    this.site.setRedAlert(false);
    TweenMax.killAll(false, false, true, false);
  }

  unpause() {
    if (this.solving || this.time <= 0) return;
    this.game_playing = true;
    this.seconds();
    this.get_reveal();
  }

  seconds() {
    //utils.trace("seconds")

    if (!this.game_playing) return;
    this.time--;
    //utils.trace("time_txt = "+time_txt)
    this.set_timer(this.time);

    //utils.trace("this.time = "+this.time)
    if (this.time < 10) {
      this.site.setRedAlert(true);
      this.red_alert();
    }

    if (this.time > 0) TweenMax.delayedCall(1, this.seconds, [], this);

    if (this.time === 0) {
      this.site.setRedAlert(false); 
      TweenMax.to($('#' + this.id + '_timer'), 1, {
      backgroundColor: "#fff",
      color: "#000",
      overwrite: 2,
      });

      utils.trace('time is up! ---------------');
      this.game_playing = false;
      TweenMax.killAll(false, false, true, false);
      this.overlay_open('lose_time');
      this.site.setRedAlert(false);
    }
  }

  set_timer(val) {
    var time_txt = String(val);
    //utils.trace("time_txt = "+time_txt+ " this.time = "+this.time)
    if (time_txt.length === 1) time_txt = 0 + time_txt;

    const time = `00:${time_txt}`;
    $('#' + this.id + '_timer').html(time);
    this.site.updateTime(time);
  }

  red_alert() {
    //utils.trace("this.alert_clr = "+this.alert_clr);
    if(this.site.getRedAlert()){
      if (this.alert_back_clr === '#d0112b') {
      this.alert_clr = '#000';
      this.alert_back_clr = '#fff';
    } else {
      this.alert_clr = '#fff';
      this.alert_back_clr = '#d0112b';
    }

    TweenMax.to($('#' + this.id + '_timer'), 1, {
      backgroundColor: this.alert_back_clr,
      color: this.alert_clr,
      overwrite: 2,
    });
    }
  }

  get_reveal() {
    //utils.trace(" ")
    //utils.trace("get_reveal")
    var total = this.letters.length;
    //utils.trace("total = "+total)
    var new_letter = Math.floor(Math.random() * (total - 1));
    utils.trace('new_letter = ' + new_letter);

    utils.trace(
      'this.grid[this.letters[new_letter]].letter = ' +
        this.grid[this.letters[new_letter]].letter,
    );
    utils.trace(
      'this.grid[this.letters[new_letter]].revealed = ' +
        this.grid[this.letters[new_letter]].revealed,
    );
    this.reveal(this.letters[new_letter]);
    this.letters.splice(new_letter, 1);

    //utils.trace(" ")
  }

  reveal(val) {
    //utils.trace("=========== reveal val = "+val);
    //utils.trace("this.grid[val].letter = "+this.grid[val].letter);

    if (!this.game_playing) return;

    this.grid[val].revealed = true;
    var letter = this.grid[val].letter;
    if (letter === '&') letter = 'and';
    if (letter === '-') letter = 'dash';
    if (letter === '!') letter = 'exclaim';
    if (letter === '?') letter = 'quest';
    if (letter === '.') letter = 'period';
    if (letter === "'") letter = 'apos';

    var letter_img = this.getLetterImg(letter);
    $('#grid_' + val).html('<img src="' + letter_img + '">');

    $('#grid_' + val + ' img').css({
      width: '100%',
      height: 'auto',
    });
    //utils.trace('this.reveal_time = '+this.reveal_time)
    if (this.letters.length > 0)
      TweenMax.delayedCall(this.reveal_time, this.get_reveal, [], this);
  }

  solve() {
    const thisObj = this;
    utils.trace('solve this.game_playing =' + this.game_playing);
    if (!this.game_playing) return;

    this.game_playing = false;
    this.solving = true;

    this.set_timer(0);
    this.btn_open('submit_btn');

    var i;

    for (i = 0; i < this.grid.length; i++) {
      //utils.trace("")
      //utils.trace("this.grid[i].letter = "+this.grid[i].letter)
      //utils.trace("this.grid[i].revealed = "+this.grid[i].revealed)

      if (
        this.grid[i].letter !== ' ' &&
        this.grid[i].letter !== '/' &&
        !this.grid[i].revealed
      ) {
        if (!this.grid[i].revealed) {
          
          //utils.trace("yo Mr white")
          $('#grid_' + i).css({
            cursor: 'pointer',
          });

          $('#grid_' + i).click(function(event) {
            

            var id = $(this).attr('btnid');
            thisObj.select_letter(id);
          });

          this.puzzle_score = 50 * this.puzzle * this.round;
          if (this.bonus) this.puzzle_score = 50000;

          utils.trace(
            'this.puzzle_score = ' +
              this.puzzle_score +
              ' this.puzzle = ' +
              this.puzzle +
              ' this.round = ' +
              this.round,
          );
        }
      }
      //utils.trace("")
    }
    this.get_next_letter();

    utils.track({
      event: 'Game',
      gameAction: 'Solve Now',
      gameTitle: 'WOF_Toss_Up',
      gameResult: '',
    });
  }
  select_letter(val) {
   
    var val = parseInt(val);
    utils.trace('select_letter val = ' + val);

    var i;

    for (i = 0; i < this.grid.length; i++) {
      if (this.selected_letter === i && this.grid[i].user_letter === ' ') {
        utils.trace(
          'this.grid[i].letter = ' +
            this.grid[i].letter +
            ' this.selected_letter = ' +
            this.selected_letter +
            ' this.grid[i].user_letter = ' +
            this.grid[i].user_letter +
            ' i = ' +
            i,
        );
        var space_img = this.getLetterImg('space');
        $('#grid_' + i).html('<img src="' + space_img + '">');
      }

      if (this.grid[i].user_letter !== ' ') {
        var letter_img = this.getLetterImg(this.grid[i].user_letter);
        $('#grid_' + i).html('<img src="' + letter_img + '">');
      }

      if (val === i) {
        utils.trace(
          'this.grid[i].letter = ' +
            this.grid[i].letter +
            ' this.selected_letter = ' +
            this.selected_letter +
            ' this.grid[i].user_letter = ' +
            this.grid[i].user_letter +
            ' i = ' +
            i,
        );
        var selected_img = this.getLetterImg('selected');
        $('#grid_' + val).html('<img src="' + selected_img + '">');
      }

      $('#grid_' + i + ' img').css({
        width: '100%',
        height: 'auto',
      });
    }

    this.selected_letter = val;

    if (this.isMobile()) {
      this.keyboard.open();
      // TODO: just hide, create reusable function for show and hide since this is called many times
      this.toggleGameButton(false, true);
    }
  }

  submit() {
    if (this.submitted) return;
    this.submitted = true;
    utils.trace('submit this.game_playing =' + this.game_playing);

    this.audio.stop();

    this.selected_letter = '';
    var result = 'win';

    var i;

    for (i = 0; i < this.grid.length; i++) {
      //utils.trace("this.grid[i].revealed = "+this.grid[i].revealed)

      if (!this.grid[i].revealed) {
        //utils.trace(" this.grid[i].user_letter  = "+this.grid[i].user_letter +" this.grid[i].letter = "+this.grid[i].letter);
        if (this.grid[i].user_letter !== this.grid[i].letter) {
          result = 'lose';
        }
      }
    }
    if (this.site.debugging) result = 'win';

    if (result === 'win') {
      this.puzzle++;

      if (this.startConfig.is_cms && this.puzzle === 2 && this.round === 1) {
        const playedPuzzleId = this.data[this.current_puzzle][PUZZLE_KEY];
        this.react_puzzle_id = playedPuzzleId;
      }

      if (this.puzzle > 3) {
        this.round++;
        result = 'next_round';
        this.puzzle = 1;
      }

      if (this.round > 3) result = 'next_bonus_round';

      this.set_score(this.puzzle_score);
      utils.trace('this.round = ' + this.round + ' result = ' + result);
      if (this.bonus) result = 'win_bonus';
    }

    if (result === 'lose' && this.bonus) {
      result = 'lose_bonus';
    }

    this.overlay_open(result);
    this.solving = false;

    utils.track({
      event: 'Game',
      gameAction: 'Submit',
      gameTitle: 'WOF_Toss_Up',
      gameResult: result,
    });

    return result;
  }

  check_navigate_to_react() {
    if (this.react_puzzle_id) {
      utils.persist_react_toss_up_data(this.react_puzzle_id, this.puzzle_score);
      $(window.location).attr('href', `${window.location.origin}${REACT_PATH}`);
    }
  }

  overlay_open(val) {
    utils.trace('overlay_open val = ' + val);
    const _self = this;
    var title = '';
    var sub = '';
    var desc = '';
    var points = '';

    if (val === 'win') {
      title = 'Nice Solve!';
      desc = "YOU'RE MOVING ON TO THE NEXT PUZZLE!";

      this.btn_open('next_puzzle_btn');
      this.audio.play('toss_win_eq');
    } else if (val === 'lose') {
      title = "Sorry, that's incorrect.";
      desc = 'This puzzle is over, <br/>but you can play it again!';

      this.btn_open('try_again_btn');

      this.audio.play('double_buzz');
    } else if (val === 'lose_time') {
      title = "Sorry, Time's Up!";
      desc = 'This puzzle is over, <br/>but you can play it again!';

      this.btn_open('try_again_btn');

      this.audio.play('double_buzz');
    } else if (val === 'next_round') {
      this.btn_open('spin_for_points_btn');

      title = 'Awesome job!';
      desc = "You've completed this round and get to spin for extra points!";

      this.audio.play('toss_win_eq');
    } else if (val === 'next_bonus_round') {
      this.btn_open('spin_for_points_btn');

      title = 'WAY TO GO!';
      desc = 'You get to spin for extra <br/> points and play the BONUS ROUND!';

      this.audio.play('toss_win_eq');
    } else if (val === 'win_bonus') {
      this.btn_open('play_again_btn');

      title = "You've solved the <br/>bonus puzzle and";
      sub = 'Won 50,000!';
      points = 'Final Score: ' + utils.thousands_separator(this.user_score);

      this.audio.play('toss_win_eq');
    } else if (val === 'lose_bonus') {
      // this.btn_open('play_again_lose_btn');
      this.btn_open('try_again_btn')

      title = 'Wrong Answer,';
      sub = "You Didn't Win The Bonus Puzzle";
      points = 'Final Score: ' + utils.thousands_separator(this.user_score);

      this.audio.play('double_buzz');
    }

    $('#' + this.id + '_grid_holder').append(
      '<div id="' + this.id + '_overlay"></div>',
    );
    $('#' + this.id + '_overlay').css({
      position: 'absolute',
    });

    $('#' + this.id + '_overlay').append(
      '<div id="' + this.id + '_overlay_holder"></div>',
    );
    $('#' + this.id + '_overlay_holder').css({
      position: 'absolute',
      display: 'table',
      'background-color': 'rgba(0, 0, 0, 0.87)',
    });

    $('#' + this.id + '_overlay_holder').append(
      '<div id="' + this.id + '_overlay_content"></div>',
    );
    $('#' + this.id + '_overlay_content').css({
      display: 'table-cell',
      'vertical-align': 'middle',
    });

    $('#' + this.id + '_overlay_content').append(
      '<div id="' + this.id + '_overlay_title">' + title + '</div>',
    );
    $('#' + this.id + '_overlay_title').css({
      color: '#fff',
      'font-weight': '300',
      'text-align': 'center',
      'text-transform': 'uppercase',
    });

    $('#' + this.id + '_overlay_content').append(
      '<div id="' + this.id + '_overlay_sub">' + sub + '</div>',
    );
    $('#' + this.id + '_overlay_sub').css({
      color: '#fff',
      'font-weight': '500',
      'text-align': 'center',
      'text-transform': 'uppercase',
    });

    $('#' + this.id + '_overlay_content').append(
      '<div id="' + this.id + '_overlay_desc">' + desc + '</div>',
    );
    $('#' + this.id + '_overlay_desc').css({
      color: '#fff',
      'font-weight': '500',
      'text-align': 'center',
      'text-transform': 'uppercase',
    });

    $('#' + this.id + '_overlay_content').append(
      '<div id="' + this.id + '_overlay_points">' + points + '</div>',
    );
    $('#' + this.id + '_overlay_points').css({
      color: '#fff',
      'font-weight': '500',
      'text-align': 'center',
      'text-transform': 'uppercase',
    });

    if (val === 'win_bonus') {
      $('#' + this.id + '_overlay').append(
        '<div id="' +
          this.id +
          '_bonus_img"><img src="' +
          utils.getImage('bonus_50000.png') +
          '"></div>',
      );
      $('#' + this.id + '_bonus_img').css({
        position: 'absolute',
      });
    }

    if (
      (val === 'win_bonus' || val === 'lose_bonus') &&
      this.config.hasBonusAd
    ) {
      $('#' + this.id + '_overlay').append(
        '<div id="' +
          tossUpIds.tossUpBonusAd +
          '"><img src="' +
          this.bonus_ad +
          '"></div>',
      );

      $('#' + tossUpIds.tossUpBonusAd).addClass('game_bonus_ad');
      $('#' + tossUpIds.tossUpBonusAd).css({
        position: 'absolute',
      });

      $('#' + tossUpIds.tossUpBonusAd).click(function(event) {
        utils.external_link(_self.config.gameBonusAdUrl, '_blank');
      });
    }

    TweenMax.set($('#' + this.id + '_overlay'), { opacity: 0 });
    TweenMax.to($('#' + this.id + '_overlay'), 0.5, {
      opacity: 1,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });

    $('img').css({
      margin: '0 auto',
    });

    if (this.isMobile()) {
      this.keyboard.close();
      this.toggleGameButton(true);
    }

    this.resize();
  }
  overlay_close() {
    this.submitted = false;
    TweenMax.to($('#' + this.id + '_overlay'), 0.5, {
      opacity: 0,
      onComplete: this.overlay_reset,
      onCompleteScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
  }
  overlay_reset() {
    $('#' + this.id + '_overlay').remove();
  }
  next_puzzle() {
    utils.trace('----------- next_puzzle -----------');
    utils.trace('next_puzzle');
    this.btn_open('wait');
    this.overlay_close();
    this.get_puzzle();
  }

  try_again() {
    utils.trace('----------- try_again -----------');
    // this.btn_open('solve_btn');
    this.overlay_close();
    this.set_puzzle();
  }

  play_again() {
    utils.trace('----------- play_again -----------');

    this.round = 1;
    this.user_score = 0;
    this.set_score(this.user_score);
    this.puzzle = 1;
    this.bonus = false;
    this.build_puzzles();
    // this.btn_open('solve_btn');
    this.overlay_close();
    this.get_puzzle();
  }

  btn_open(id) {
    if (id === this.current_btn) {
      return;
    }
    this.btn_close(this.current_btn);
    this.current_btn = id;
    TweenMax.to($(`.${id}`), 0.5, {
      delay: 0.5,
      opacity: 1,
      onStart: this.site.div_display,
      onStartParams: ['.' + id, 'block'],
      onStartScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
  }

  btn_close(id) {
    if (!id) return;
    TweenMax.to($(`.${id}`), 0.5, {
      opacity: 0,
      onComplete: this.site.div_display,
      onCompleteParams: ['.' + id, 'none'],
      onCompleteScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
  }

  spin_open() {
    this.site.open('wheel');
  }
  spin_close() {
    //this.btn_close('spin_for_points_btn');
    //this.btn_open('solve_btn');
    //this.wheel.close();
    //this.site.open('interstitial');
  }

  next_round() {
    this.site.open('play_now');
    this.overlay_close();
    // this.btn_open('solve_btn');
  }

  set_score(val) {
    this.user_score = this.user_score + val;
    $('#score_val').html(utils.thousands_separator(this.user_score));
    $('#score_val').css({ color: '#fff' });
  }

  add_spaces(txt, max) {
    //utils.trace("add_spaces txt ="+txt+" txt.length = "+txt.length+" max = "+max)
    //utils.trace("------- spaces_l ="+spaces_l);
    var i;
    var spaces_r = max - txt.length;

    for (i = 0; i < spaces_r; i++) {
      txt = txt + ' ';
    }
    utils.trace('-' + txt + '-');
    return txt;
  }

  key_pressed(keyCode) {
    utils.trace(
      'pressed keyCode = ' +
        keyCode +
        ' this.game_playing = ' +
        this.game_playing,
    );
    if (!this.solving) return;

    var user_letter = '';

    // eslint-disable-next-line default-case
    switch (keyCode) {
      case 8:
        this.get_last_letter();
        return;
      case 46:
        user_letter = 'space';
        break;
      case 65:
        user_letter = 'a';
        break;
      case 66:
        user_letter = 'b';
        break;
      case 67:
        user_letter = 'c';
        break;
      case 68:
        user_letter = 'd';
        break;
      case 69:
        user_letter = 'e';
        break;
      case 70:
        user_letter = 'f';
        break;
      case 71:
        user_letter = 'g';
        break;
      case 72:
        user_letter = 'h';
        break;
      case 73:
        user_letter = 'i';
        break;
      case 74:
        user_letter = 'j';
        break;
      case 75:
        user_letter = 'k';
        break;
      case 76:
        user_letter = 'l';
        break;
      case 77:
        user_letter = 'm';
        break;
      case 78:
        user_letter = 'n';
        break;
      case 79:
        user_letter = 'o';
        break;
      case 80:
        user_letter = 'p';
        break;
      case 81:
        user_letter = 'q';
        break;
      case 82:
        user_letter = 'r';
        break;
      case 83:
        user_letter = 's';
        break;
      case 84:
        user_letter = 't';
        break;
      case 85:
        user_letter = 'u';
        break;
      case 86:
        user_letter = 'v';
        break;
      case 87:
        user_letter = 'w';
        break;
      case 88:
        user_letter = 'x';
        break;
      case 89:
        user_letter = 'y';
        break;
      case 90:
        user_letter = 'z';
        break;
      case 13:
        user_letter = 'submit';
        break;
    }

    this.letter_selected(user_letter);
  }
  letter_selected(user_letter) {
    utils.trace(
      'user_letter = ' +
        user_letter +
        ' this.selected_letter = ' +
        this.selected_letter,
    );

    if (user_letter === '') {
      utils.trace('Why Mr. White?');
      return;
    }

    if (user_letter === 'submit' || user_letter === 'enter') {
      this.submit();
      return;
    }
    this.grid[this.selected_letter].user_letter = user_letter;

    var letter_img = this.getLetterImg(user_letter);
    utils.trace('letter_img = ' + letter_img);
    $('#grid_' + this.selected_letter).html('<img src="' + letter_img + '">');

    $('#grid_' + this.selected_letter + ' img').css({
      width: '100%',
      height: 'auto',
    });

    this.get_next_letter();

    utils.trace('this.selected_letter = ' + this.selected_letter);
  }

  get_next_letter() {
    var i;

    for (i = 0; i < this.grid.length; i++) {
      utils.trace(
        'i = ' +
          i +
          ' this.grid[i].user_letter = ' +
          this.grid[i].user_letter +
          ' this.grid[i].revealed = ' +
          this.grid[i].revealed +
          ' this.grid[i].letter = ' +
          this.grid[i].letter,
      );

      if (
        this.grid[i].user_letter === ' ' &&
        !this.grid[i].revealed &&
        this.grid[i].letter !== ' '
      ) {
        this.last_letter = this.selected_letter;
        this.selected_letter = -1;
        this.select_letter(i);
        return;
      }
    }
  }
  get_last_letter() {
    var i;

    for (i = this.selected_letter - 1; i > -1; i--) {
      utils.trace(
        'i = ' +
          i +
          ' this.grid[i].user_letter = ' +
          this.grid[i].user_letter +
          ' this.grid[i].revealed = ' +
          this.grid[i].revealed +
          ' this.grid[i].letter = ' +
          this.grid[i].letter,
      );

      if (!this.grid[i].revealed) {
        this.grid[i].user_letter = ' ';
        this.select_letter(i);
        return;
      }
    }
  }

  toggleGameButton(show, byPassComplete) {
    if (show) {
      TweenMax.to($('#game_btns'), 0.5, {
        opacity: 1,
        onStart: this.site.div_display,
        onStartParams: ['#game_btns', 'flex'],
        onStartScope: this,
        ease: 'Power1.easeInOut',
        overwrite: 2,
      });
    } else {
      TweenMax.to($('#game_btns'), 0.5, {
        opacity: 0,
        onComplete: byPassComplete === true ? () => {} : this.site.div_display,
        onCompleteParams: ['#game_btns', 'none'],
        onCompleteScope: this,
        ease: 'Power1.easeInOut',
        overwrite: 2,
      });
    }
  }

  open() {
    if (this.active) return;
    this.active = true;
    utils.trace(this.id + ' ---------------  open ');
    // if(document.getElementById('audio_btn')){
    //   document.getElementById("audio_btn").style.cssText = "opacity: 1;";
    // }
    //  if(document.getElementById(this.id)){
    //   document.getElementById(this.id).style.cssText="opacity:1;display:block"
    // }
    // this.toggleGameButton(true);
    // if(document.getElementById(this.id + '_grid')){
    //   document.getElementById(this.id + '_grid').style.cssText="opacity:1;display:block"
    // }
    // if(document.getElementById('retro_frame')){
    //   document.getElementById('retro_frame').style.cssText="opacity:1;display:block"
    // }
    TweenMax.set($('#audio_btn'), { opacity: 0 });
    TweenMax.to($('#audio_btn'), 0.5, {
      opacity: 1,
      onStart: this.site.div_display,
      onStartParams: ['#audio_btn', 'block'],
      onStartScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
    TweenMax.to($('#' + this.id), 0.5, {
      opacity: 1,
      onStart: this.site.div_display,
      onStartParams: ['#' + this.id, 'flex'],
      onStartScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
    this.toggleGameButton(true);
    TweenMax.to($('#' + this.id + '_grid'), 0.5, {
      opacity: 1,
      onStart: this.site.div_display,
      onStartParams: ['#' + this.id + '_grid', 'block'],
      onStartScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
    TweenMax.to($('#retro_frame'), 0.5, {
      opacity: 1,
      onStart: this.site.div_display,
      onStartParams: ['#retro_frame', 'block'],
      onStartScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });

    this.get_puzzle();
  }

  close() {
    utils.trace(this.id + ' --------------------- close');
    this.active = false;
    this.keyboard.close();
    this.audio.stop();

    utils.trace('this.site.next = ' + this.site.new);

    if (this.site.new === 'wheel') {
      TweenMax.to($('#' + this.id + '_grid'), 0.5, {
        opacity: 0,
        onComplete: this.site.div_display,
        onCompleteParams: ['#' + this.id + '_grid', 'none'],
        onCompleteScope: this,
        ease: 'Power1.easeInOut',
        overwrite: 2,
      });
    } else {
      TweenMax.to($('#' + this.id), 0.5, {
        opacity: 0,
        onComplete: this.site.div_display,
        onCompleteParams: ['#' + this.id, 'none'],
        onCompleteScope: this,
        ease: 'Power1.easeInOut',
        overwrite: 2,
      });
    }
    TweenMax.to($('#retro_frame'), 0.5, {
      opacity: 0,
      onStart: this.site.div_display,
      onStartParams: ['#retro_frame', 'none'],
      onStartScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
  }

  resize() {
    utils.trace(this.id + ' resize ');

    if (this.useMobileStyle()) {
      $('#' + this.id + '_timer').css({
        width: '6%',
        margin: '0.25% 2% 0.25% 4%',
        padding: '0.01% 0.5%',
      });
      $('#' + this.id + '_btns').css({
        bottom: '0px',
      });
      $('#' + tossUpIds.tossUpBonusAd).css({
        width: this.bonus_ad_w() + 'px',
        height: this.bonus_ad_h() + 'px',
        top: this.bonus_ad_t() + 'px',
        left: this.bonus_ad_l() + 'px',
      });
    } else {
      $('#' + this.id + '_timer').css({
        width: '6%',
        margin: '1.5% 2% 1% 4%',
        padding: '0.5%',
      });

      $('#' + tossUpIds.tossUpBonusAd).css({
        width: this.bonus_ad_w() + 'px',
        height: this.bonus_ad_h() + 'px',
        top: this.bonus_ad_t() + 'px',
        left: this.bonus_ad_l() + 'px',
      });
    }

    $('#' + this.id).css({
      width: this.width() + 'px',
      height: '100%',
    });

    $('#' + this.id + '_grid').css({
      width: this.width() + 'px',
      flex: '1',
    });

    $('#' + this.id + '_timer').css({
      'font-size': this.score_size() + 'px',
      'line-height': this.score_leading() + 'px',
    });

    TweenMax.set($('#' + this.id + '_timer'), {
      borderRadius: this.timer_corner() + 'px',
    });

    if (this.user_score === 0) {
      $('#score_val').css({
        color: '#fff',
      });
    } else {
      $('#score_val').css({
        color: '#FFF',
      });
    }

    $('#' + this.id + '_grid_img').css({
      width: this.grid_img_w() + 'px',
      height: this.grid_img_h() + 'px',
      'padding-top': this.grid_img_t() + 'px',
      'padding-left': this.grid_img_l() + 'px',
    });

    $('#' + this.id + '_btns').css({
      width: this.width() + 'px',
    });

    $('.letter').css({
      border: '0px solid #0a0c10',
      width: this.letter_w() + 'px',
      height: this.letter_h() + 'px',
    });

    $('#' + this.id + '_overlay').css({
      width: this.width() + 'px',
      height: this.overlay_h() + 'px',
      top: '0px',
      left: '0px',
    });

    $('#' + this.id + '_overlay_holder').css({
      width: this.overlay_w() + 'px',
      height: this.overlay_holder_h() + 'px',
      top: this.overlay_t() + 'px',
      left: this.overlay_l() + 'px',
      padding: this.overlay_tb() + '0px ' + this.overlay_lr() + 'px',
    });

    $('#' + this.id + '_overlay_title').css({
      'font-size': this.overlay_title_size() + 'px',
      'line-height': this.overlay_title_leading() + 'px',
      'padding-bottom': this.overlay_p() + 'px',
    });

    $('#' + this.id + '_overlay_sub').css({
      'font-size': this.overlay_sub_size() + 'px',
      'line-height': this.overlay_sub_leading() + 'px',
      'padding-bottom': this.overlay_p() + 'px',
    });

    $('#' + this.id + '_overlay_desc').css({
      'font-size': this.overlay_desc_size() + 'px',
      'line-height': this.overlay_desc_leading() + 'px',
      'padding-bottom': this.overlay_p() + 'px',
    });

    $('#' + this.id + '_overlay_points').css({
      'font-size': this.overlay_points_size() + 'px',
      'line-height': this.overlay_points_leading() + 'px',
      'padding-bottom': this.overlay_p() + 'px',
    });

    $('#' + this.id + '_bonus_img').css({
      width: this.bonus_img_wh() + 'px',
      height: this.bonus_img_wh() + 'px',
      top: this.bonus_img_t() + 'px',
      left: this.bonus_img_l() + 'px',
    });

    $('#' + this.id + '_grid_letters').css({
      width: this.grid_img_w() + 'px',
      height: this.grid_img_h() + 'px',
      top: this.grid_img_t() + 'px',
      left: this.grid_img_l() + 'px',
    });

    for (let i = 0; i < this.grid.length; i++) {
      $('#grid_' + i).css({
        position: 'absolute',
        left: this.grid[i].x * this.scale() + 'px',
        top: this.grid[i].y * this.scale() + 'px',
      });
    }
  }

  isMobile() {
    return this.site.device !== 'desktop';
  }

  useMobileStyle() {
    // Currently defaulting to desktop style for sizing
    return this.site.useMobileStyle && this.isMobile();
  }

  bonus_ad_w() {
    var value = GRID_IMG_WIDTH * utils.scale();
    if (this.useMobileStyle())
      value = utils.window_width() * ADJUSTMENT_CONSTS.MOBILE_VIEW_WIDTH;
    return value;
  }

  bonus_ad_h() {
    var value = (68 / GRID_IMG_WIDTH) * this.bonus_ad_w();
    return value;
  }

  bonus_ad_t() {
    var value = 380 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.28;
    return value;
  }

  bonus_ad_l() {
    var value = 45 * utils.scale();
    if (this.useMobileStyle())
      value = utils.window_width() * ADJUSTMENT_CONSTS.MOBILE_VIEW_PADDING;
    return value;
  }

  bonus_img_t() {
    var value = 50 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.03;
    return value;
  }

  bonus_img_l() {
    var value = 40 * utils.scale();
    if (this.useMobileStyle())
      value = utils.window_width() * ADJUSTMENT_CONSTS.MOBILE_VIEW_PADDING;
    return value;
  }

  bonus_img_wh() {
    var value = 223 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.2;
    return value;
  }

  overlay_p() {
    var value = 5 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.005;
    return value;
  }

  overlay_points_size() {
    var value = 25 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.02;
    return value;
  }

  overlay_points_leading() {
    var value = 30 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.025;
    return value;
  }

  overlay_sub_size() {
    var value = 44 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.03;
    return value;
  }

  overlay_sub_leading() {
    var value = 46 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.035;
    return value;
  }

  overlay_title_size() {
    var value = 44 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.03;
    return value;
  }

  overlay_title_leading() {
    var value = 46 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.035;
    return value;
  }

  overlay_desc_size() {
    var value = 25 * utils.scale();
    if (this.useMobileStyle())
      value =
        utils.window_width() * 0.02 * ADJUSTMENT_CONSTS.MOBILE_WIDTH_SCALE;
    return value;
  }

  overlay_desc_leading() {
    var value = 30 * utils.scale();
    if (this.useMobileStyle())
      value =
        utils.window_width() * 0.025 * ADJUSTMENT_CONSTS.MOBILE_WIDTH_SCALE;
    return value;
  }

  overlay_tb() {
    var value = 0 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0;
    return value;
  }

  overlay_lr() {
    var value = 25 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.05;
    return value;
  }

  overlay_w() {
    var value = 583 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.42;
    return value;
  }

  overlay_h() {
    var value = 410 * utils.scale();
    if (this.useMobileStyle())
      value =
        utils.window_width() * 0.18 * ADJUSTMENT_CONSTS.MOBILE_WIDTH_SCALE;
    return value;
  }

  overlay_holder_h() {
    var value = 215 * utils.scale();
    if (this.useMobileStyle())
      value =
        utils.window_width() * 0.18 * ADJUSTMENT_CONSTS.MOBILE_WIDTH_SCALE;
    return value;
  }

  overlay_t() {
    var value = 85 * utils.scale();
    if (this.useMobileStyle())
      value =
        utils.window_width() * 0.047 * ADJUSTMENT_CONSTS.MOBILE_WIDTH_SCALE;
    return value;
  }

  overlay_l() {
    var value = 160 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.244;
    return value;
  }

  btns_h() {
    var value = 86 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.09;
    return value;
  }

  letter_w() {
    var value = LETTER_W * this.scale();
    return value;
  }

  letter_h() {
    var value = LETTER_H * this.letter_w();
    return value;
  }

  grid_letters_t() {
    var value = GRID_TOP * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.005;
    return value;
  }

  grid_letters_l() {
    var value = GRID_LEFT * utils.scale();
    if (this.useMobileStyle())
      value = utils.window_width() * ADJUSTMENT_CONSTS.MOBILE_VIEW_PADDING;
    return value;
  }

  grid_img_t() {
    var value = GRID_TOP * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.005;
    return value;
  }

  grid_img_l() {
    var value = GRID_LEFT * utils.scale();
    if (this.useMobileStyle())
      value = utils.window_width() * ADJUSTMENT_CONSTS.MOBILE_VIEW_PADDING;
    return value;
  }

  grid_img_w() {
    var value = GRID_IMG_WIDTH * utils.scale();
    if (this.useMobileStyle())
      value = utils.window_width() * ADJUSTMENT_CONSTS.MOBILE_VIEW_WIDTH;
    return value;
  }

  grid_img_h() {
    var value = (GRID_IMG_HEIGHT / GRID_IMG_WIDTH) * this.grid_img_w();
    return value;
  }

  action_btn_w() {
    var value = 285 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.3;
    return value;
  }

  btn_holer_tb() {
    var value = 25 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.015;
    return value;
  }

  timer_corner() {
    var value = 7 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.01;
    return value;
  }

  score_size() {
    var value = 18 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.02;
    return value;
  }

  score_leading() {
    var value = 21 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.03;
    return value;
  }

  width() {
    var value = utils.max_width * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 1;
    return value;
  }

  height() {
    var value = (602 / 961) * this.width();
    if (this.useMobileStyle())
      value =
        ADJUSTMENT_CONSTS.MOBILE_HEIGHT *
        this.width() *
        ADJUSTMENT_CONSTS.MOBILE_WIDTH_SCALE;
    return value;
  }

  scale() {
    var value = utils.scale();
    if (this.useMobileStyle())
      value =
        (utils.window_width() / 1229) * ADJUSTMENT_CONSTS.MOBILE_WIDTH_SCALE;
    return value;
  }

  preload_letters() {
    utils.trace(this.id + ' preload_letters');
    var i;
    var game_letters = [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
      'and',
      'apos',
      'dash',
      'exclaim',
      // 'quest',
      'selected',
      'space',
      'period',
    ];

    //utils.trace("this.site.active_module = "+this.site.active_module)

    $('body').append('<div id="preload_imgs"></div>');

    $('#preload_imgs').css({
      position: 'absolute',
      display: 'none',
    });

    for (i = 0; i < game_letters.length; i++) {
      var new_img = new Image();
      new_img.id = game_letters[i];
      new_img.onload = function() {
        utils.trace(this.id);
      };

      var letter_img = this.getLetterImg(game_letters[i]);
      $('#preload_imgs').append('<img src="' + letter_img + '">');

      new_img.src = letter_img;
    }
  }
}

export default Game;
