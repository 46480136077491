import React from 'react';
import HowToPlay from './HowToPlay';

export default ({ children, htpProps }) => (
  <div id="game_bottom">
    {/** TODO, scroll for how to play if necessary */}
    <div id="game_bottom_content">
      <div
        id="game_bottom_holder"
        className={`hide-able${htpProps.isOpen ? ' hide' : ''}`}>
        {children}
      </div>
      {/** TODO, bigger font flex column mobile */}
      <HowToPlay {...htpProps} />
    </div>
  </div>
);
