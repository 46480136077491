/* eslint-disable no-restricted-globals */
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import * as serviceWorker from './serviceWorker';
// import BingoPage from './pages/BingoPage';
import TossUpChallengePage from './pages/TossUpChallengePage';
// import BonusPuzzlePage from './pages/BonusPuzzlePage';
// import GiveawaysPage from './pages/GiveawaysPage';
// import Header from './components/Header';
// import Footer from './components/Footer';
// import PageNotFound from './components/PageNotFound';
import { REACT_PATH } from './components/TossUpChallenge/code/js/utils';
import './scss/main.scss';

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);


const Root: React.FC<any> = ({ store }) => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route
          path="/"
          component={TossUpChallengePage}
        />
      </Switch>
    </Router>
  </Provider>
  );
ReactDOM.render(<Root store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
