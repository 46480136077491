import $ from 'jquery';
import TweenMax from '../gsap/TweenMax';
import Draggable from '../gsap/Draggable';
import utils, { ADJUSTMENT_CONSTS } from './utils';
import { tossUpIds } from '../../../../analyticsTags';

class Wheel {
  id = 'wheel';
  active = false;
  data = [];
  spinning = false;
  complete = false;
  site = null;
  audio = null;
  config = null;

  constructor(site, audio) {
    this.site = site;
    this.audio = audio;
  }

  getWheelWedge(fileName) {
    return require(`../images/wheel/${fileName}`);
  }

  initialize(config) {
    this.config = config;
    var thisobj = this;
    const getWheelWedge = this.getWheelWedge;

    $(window).resize(function() {
      thisobj.resize();
    });

    this.data.push({
      val: 1000,
      rotation: 7,
      wedge: this.config.wedgePath,
    });
    this.data.push({
      val: 700,
      rotation: 22,
      wedge: getWheelWedge('700blue.png'),
    });
    this.data.push({
      val: 900,
      rotation: 37,
      wedge: getWheelWedge('900yellow.png'),
    });
    this.data.push({
      val: 650,
      rotation: 52,
      wedge: getWheelWedge('650violet.png'),
    });
    this.data.push({
      val: 'bankrupt',
      rotation: 67,
      wedge: getWheelWedge('bankrupt.png'),
    });
    this.data.push({
      val: 900,
      rotation: 82,
      wedge: getWheelWedge('900pink.png'),
    });
    this.data.push({
      val: 500,
      rotation: 97,
      wedge: getWheelWedge('500green.png'),
    });
    this.data.push({
      val: 550,
      rotation: 112,
      wedge: getWheelWedge('550blue.png'),
    });
    this.data.push({
      val: 600,
      rotation: 127,
      wedge: getWheelWedge('600red.png'),
    });
    this.data.push({
      val: 500,
      rotation: 142,
      wedge: getWheelWedge('500pink.png'),
    });
    this.data.push({
      val: 700,
      rotation: 157,
      wedge: getWheelWedge('700yellow.png'),
    });
    this.data.push({
      val: 500,
      rotation: 172,
      wedge: getWheelWedge('500violet.png'),
    });
    this.data.push({
      val: 800,
      rotation: 187,
      wedge: getWheelWedge('800orange.png'),
    });
    this.data.push({
      val: 600,
      rotation: 202,
      wedge: getWheelWedge('600blue.png'),
    });
    this.data.push({
      val: 700,
      rotation: 217,
      wedge: getWheelWedge('700red.png'),
    });
    this.data.push({
      val: 900,
      rotation: 232,
      wedge: getWheelWedge('900yellow.png'),
    });
    this.data.push({
      val: 500,
      rotation: 247,
      wedge: getWheelWedge('500green.png'),
    });
    this.data.push({
      val: 5000,
      rotation: 262,
      wedge: getWheelWedge('5000.png'),
    });
    this.data.push({
      val: 'bankrupt',
      rotation: 277,
      wedge: getWheelWedge('bankrupt.png'),
    });
    this.data.push({
      val: 900,
      rotation: 292,
      wedge: getWheelWedge('900orange.png'),
    });
    this.data.push({
      val: 500,
      rotation: 307,
      wedge: getWheelWedge('500green.png'),
    });
    this.data.push({
      val: 650,
      rotation: 322,
      wedge: getWheelWedge('650pink.png'),
    });
    this.data.push({
      val: 500,
      rotation: 337,
      wedge: getWheelWedge('500violet.png'),
    });
    this.data.push({
      val: 800,
      rotation: 360,
      wedge: getWheelWedge('800red.png'),
    });

    this.render();
  }

  render() {
    var thisobj = this;
    const audio = this.audio;

    utils.trace(this.id + ' render');

    $('#game_bottom_holder').append('<div id="' + this.id + '"></div>');
    $('#' + this.id).css({
      position: 'absolute',
      top: '0px',
      left: '0px',
      display: 'none',
      overflow: 'hidden',
    });
    TweenMax.set($('#' + this.id), { opacity: 0 });

    $('#' + this.id).append('<div id="' + this.id + '_spin_now"></div>');
    $('#' + this.id + '_spin_now').css({
      position: 'absolute',
    });

    if (this.useMobileStyle()) {
      $('#' + this.id + '_spin_now').html(
        '<img src="' + this.getWheelWedge('spin_now_arrow_mobile.png') + '">',
      );
    } else {
      $('#' + this.id + '_spin_now').html(
        '<img src="' + this.getWheelWedge('spin_now_arrow.png') + '">',
      );
    }

    $('#' + this.id).append(
      `<div id="${tossUpIds.tossUpWheelImg}"><img src="${this.config.wheelPath}"/></div>`,
    );
    $(`#${tossUpIds.tossUpWheelImg}`).css({
      position: 'absolute',
    });

    $(`#${tossUpIds.tossUpWheelImg}`).click(function(event) {
      if (!thisobj.isMobile()) return;
      thisobj.audio.play('wheel_spin');
    });

    Draggable.create(`#${tossUpIds.tossUpWheelImg}`, {
      type: 'rotation',
      throwProps: true,
      minDuration: 4,
      bounds: { minRotation: 360, maxRotation: 11000 },
      onThrowComplete: function(endRotation) {
        utils.trace('endValue = ' + this.rotation);
        // thisobj.result(this.rotation);
      },
      onDragStart: function() {
        audio.play('wheel_spin');
        thisobj.spinning = true;
      },
      onDragEnd: function(endRotation) {
        // utils.trace(
        //   'endRotation ' +
        //     this.endRotation +
        //     ' and the duration is ' +
        //     this.tween.duration() +
        //     ' seconds.',
        // );
        //thisobj.spin(this.endRotation);
      },
    });

    $('#' + this.id).append('<div id="' + this.id + '_btns"></div>');
    $('#' + this.id + '_btns').css({
      'background-color': 'rgba(0,0,0,0.5)',
      position: 'absolute',
      bottom: '0px',
    });

    $('#' + this.id + '_btns').append(
      '<div id="' + this.id + '_btn_holder"></div>',
    );

    const {
      wheelSpinClass,
      wheelSpinText,
      wheelContinueClass,
      wheelContinueText,
    } = this.config || {};

    this.site.game.renderButton(
      this.id,
      'spin',
      wheelSpinClass,
      wheelSpinText,
      true,
    );

    $('.spin_btn').click(function(event) {
      if (thisobj.spinning) {
        thisobj.spinning = false;
      }
      thisobj.audio.play('wheel_spin');
      thisobj.spin();
      utils.track({
        event: 'Game',
        gameAction: 'Spin',
        gameTitle: 'WOF_Toss_Up',
        gameResult: '',
      });
    });

    this.site.game.renderButton(
      this.id,
      'continue',
      wheelContinueClass,
      wheelContinueText,
    );

    $('.continue_btn').css({
      display: 'none',
    });

    $('.continue_btn').click(function(event) {
      thisobj.site.open('interstitial');
      utils.track({
        event: 'Game',
        gameAction: 'Continue',
        gameTitle: 'WOF_Toss_Up',
        gameResult: '',
      });
    });

    this.resize();
  }

  spin(rotation) {
    utils.trace('spin ' + this.spinning);
    if (this.spinning) return;
    this.spinning = true;

    utils.trace('rotation = ' + rotation);
    if (rotation === undefined)
      rotation = Math.floor(Math.random() * (1440 - 720 + 1)) + 720;

    TweenMax.to($(`#${tossUpIds.tossUpWheelImg}`), 4, {
      rotation: rotation,
      ease: 'Power1.easeInOut',
      onComplete: this.result,
      onCompleteParams: [rotation],
      onCompleteScope: this,
      overwrite: 2,
    });
  }

  continue() {
    utils.trace('continue');
  }

  result(val) {
    utils.trace('done val = ' + val);

    /*if(val < 200) {
            this.spinning = false;
            this.spin();
            return
        }*/

    if (this.complete) return;
    this.complete = true;

    var multiple = Math.round(Math.floor(val / 360));
    var filtered_val = val - 360 * multiple;
    utils.trace('multiple ==== ' + multiple);
    utils.trace('filtered_val ==== ' + filtered_val);
    if (val < 0) val = val + 360;

    val = filtered_val;

    var i;

    for (i = 0; i < this.data.length; i++) {
      //utils.trace("this.data[i].val = "+this.data[i].val+" this.data[i].rotation = "+this.data[i].rotation);

      var rotation = this.data[i].rotation;

      var next_rotation_id = (next_rotation_id = i - 1);
      if (next_rotation_id < 0) next_rotation_id = this.data.length - 1;
      if (next_rotation_id > this.data.length - 1) next_rotation_id = 0;

      var next_rotation = this.data[next_rotation_id].rotation;
      if (next_rotation_id === this.data.length - 1) next_rotation = 0;

      //utils.trace("rotation = "+rotation+" next_rotation = "+next_rotation)

      //utils.trace("val = "+val+" this.data[i].val = "+this.data[i].val+" rotation = "+rotation+" next_rotation = "+next_rotation)

      if (rotation >= val && next_rotation < val) {
        utils.trace('You won ! = ' + this.data[i].val);
        this.overlay_build(this.data[i]);
      }
    }
  }

  overlay_build(options) {
    utils.trace(
      'overlay_open options = ' +
        options.val +
        ' options.wedge = ' +
        options.wedge,
    );
    var thisobj = this;
    var title = '';
    var sub = '';
    var desc = '';
    var points = '';

    if (this.site.debugging) {
      options.val = 1000;
      options.wedge = this.data[0].wedge;
    }

    utils.trace('options.wedge = ' + options.wedge);

    if (options.val === 'bankrupt') {
      title = 'OH NO!';
      sub = "YOU'VE LOST ALL<br/> YOUR POINTS";
      desc = 'BETTER LUCK IN <br/>THE NEXT ROUND';

      this.audio.play('bankrupt');
    } else if (options.val === 1000) {
      title = 'CONGRATULATIONS';
      sub = "YOU'VE WON " + utils.thousands_separator(options.val) + '!';
      desc = 'GOOD LUCK IN THE NEXT ROUND!';

      this.audio.play('goodie');
    } else {
      title = 'GREAT SPIN!';
      sub = "YOU'VE WON";
      points = options.val + ' POINTS!';

      this.audio.play('toss_win_eq');
    }

    this.site.game.btn_open('continue_btn');

    $('#' + this.id).append('<div id="' + this.id + '_overlay"></div>');

    $('#' + this.id + '_overlay').css({
      position: 'absolute',
    });

    $('#' + this.id + '_overlay').append(
      '<div id="' + this.id + '_overlay_holder"></div>',
    );
    $('#' + this.id + '_overlay_holder').css({
      display: 'table',
      position: 'absolute',
      'background-color': 'rgba(0, 0, 0, 0.87)',
    });

    $('#' + this.id + '_overlay_holder').append(
      '<div id="' + this.id + '_overlay_content"></div>',
    );
    $('#' + this.id + '_overlay_content').css({
      display: 'table-cell',
      'vertical-align': 'middle',
    });

    $('#' + this.id + '_overlay_content').append(
      '<div id="' + this.id + '_overlay_title">' + title + '</div>',
    );
    $('#' + this.id + '_overlay_title').css({
      color: '#fff',
      'font-family': 'Lato',
      'font-weight': '300',
      'text-align': 'center',
      'text-transform': 'uppercase',
    });

    $('#' + this.id + '_overlay_content').append(
      '<div id="' + this.id + '_overlay_sub">' + sub + '</div>',
    );
    $('#' + this.id + '_overlay_sub').css({
      color: '#fff',
      'font-family': 'Lato',
      'font-weight': '500',
      'text-align': 'center',
      'text-transform': 'uppercase',
    });

    $('#' + this.id + '_overlay_content').append(
      '<div id="' + this.id + '_overlay_desc">' + desc + '</div>',
    );
    $('#' + this.id + '_overlay_desc').css({
      color: '#fff',
      'font-family': 'Lato',
      'font-weight': '500',
      'text-align': 'center',
      'text-transform': 'uppercase',
    });

    $('#' + this.id + '_overlay_content').append(
      '<div id="' + this.id + '_overlay_points">' + points + '</div>',
    );
    $('#' + this.id + '_overlay_points').css({
      color: '#fff',
      'font-family': 'Lato',
      'font-weight': '500',
      'text-align': 'center',
      'text-transform': 'uppercase',
    });

    var new_img = new Image();
    new_img.onload = function() {
      thisobj.overlay_open();
    };

    var wedge_img = options.wedge;
    $('#' + this.id + '_overlay').append(
      '<div id="' + this.id + '_wedge"><img src="' + wedge_img + '"></div>',
    );
    $('#' + this.id + '_wedge').css({
      position: 'absolute',
    });

    new_img.src = wedge_img;

    $('#' + this.id + '_wedge img').css({
      width: '100%',
      height: 'auto',
    });

    TweenMax.set($('#' + this.id + '_overlay'), { opacity: 0 });

    this.site.game.btn_close('spin_btn');
    this.site.game.btn_open('continue_btn');

    utils.trace(
      'options.val = ' +
        options.val +
        ' this.site.game.user_score = ' +
        this.site.game.user_score,
    );

    var wheel_score = options.val;
    if (wheel_score === 'bankrupt')
      wheel_score = this.site.game.user_score * -1;

    utils.trace('wheel_score = ' + wheel_score);
    this.site.game.set_score(wheel_score);
  }

  overlay_open() {
    TweenMax.to(
      $('#' + this.id + '_overlay').add('#' + this.id + '_spin_now'),
      0.5,
      { opacity: 0, ease: 'Power1.easeInOut', overwrite: 2 },
    );
    TweenMax.to($('#' + this.id + '_overlay'), 0.5, {
      opacity: 1,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
    this.resize();
  }
  overlay_close() {
    TweenMax.to($('#' + this.id + '_overlay'), 0.5, {
      opacity: 0,
      onComplete: this.overlay_reset,
      onCompleteScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
  }
  overlay_reset() {
    $('#' + this.id + '_overlay').remove();
  }

  open() {
    utils.trace(this.id + ' ---------------  open');

    if (this.active || this.complete) return;
    this.active = true;
    this.complete = false;

    TweenMax.set($(`#${tossUpIds.tossUpWheelImg}`), { rotation: 0 });

    this.site.game.btn_open('spin_btn');
    TweenMax.set($('#' + this.id + '_spin_now'), { opacity: 1 });
    TweenMax.to($('#' + this.id), 0.5, {
      opacity: 1,
      onStart: this.site.div_display,
      onStartParams: ['#' + this.id, 'block'],
      onStartScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
  }

  close() {
    utils.trace(this.id + ' --------------------- close');
    this.spinning = false;
    this.active = false;
    this.complete = false;
    this.overlay_close();
    this.site.game.btn_close('continue_btn');
    $(`#${tossUpIds.tossUpWheelImg}`).css({ rotation: 0 });
    TweenMax.to($('#' + this.id), 0.5, {
      opacity: 0,
      onComplete: this.site.div_display,
      onCompleteParams: ['#' + this.id, 'none'],
      onCompleteScope: this,
      ease: 'Power1.easeInOut',
      overwrite: 2,
    });
  }

  resize() {
    $('#' + this.id).css({
      width: this.width() + 'px',
      height: '100%', //this.height() + 'px',
    });

    $(`#${tossUpIds.tossUpWheelImg}`).css({
      width: this.img_wh() + 'px',
      height: this.img_wh() + 'px',
      top: this.isMobile() ? '45%' :'30%', //this.img_t() + 'px',
      left: this.img_l() + 'px',
    });

    $('#' + this.id + '_spin_now').css({
      width: this.spin_now_w() + 'px',
      height: this.spin_now_h() + 'px',
      top: this.isMobile() ? '30%' :'14%', //this.spin_now_t() + 'px',
      left: this.spin_now_l() + 'px',
    });

    $('#' + this.id + '_overlay_holder').css({
      width: this.overlay_w() + 'px',
      height: this.overlay_holder_h() + 'px',
      top: this.overlay_t() + 'px',
      left: this.overlay_l() + 'px',
      padding: '0px ' + this.overlay_lr() + 'px',
    });

    $('#' + this.id + '_overlay').css({
      width: this.width() + 'px',
      height: this.overlay_h() + 'px',
      top: '0px',
      left: '0px',
    });

    $('#' + this.id + '_overlay_title').css({
      'font-size': this.overlay_title_size() + 'px',
      'line-height': this.overlay_title_leading() + 'px',
      'padding-bottom': this.overlay_p() + 'px',
    });

    $('#' + this.id + '_overlay_sub').css({
      'font-size': this.overlay_sub_size() + 'px',
      'line-height': this.overlay_sub_leading() + 'px',
      'padding-bottom': this.overlay_p() + 'px',
    });

    $('#' + this.id + '_overlay_desc').css({
      'font-size': this.overlay_desc_size() + 'px',
      'line-height': this.overlay_desc_leading() + 'px',
      'padding-bottom': this.overlay_p() + 'px',
    });

    $('#' + this.id + '_overlay_points').css({
      'font-size': this.overlay_points_size() + 'px',
      'line-height': this.overlay_points_leading() + 'px',
      'padding-bottom': this.overlay_p() + 'px',
    });

    $('#' + this.id + '_wedge').css({
      width: this.wedge_w() + 'px',
      height: this.wedge_h() + 'px',
      top: this.wedge_t() + 'px',
      left: this.wedge_l() + 'px',
    });

    $('#' + this.id + '_btns').css({
      width: this.width() + 'px',
      // height: this.btns_h() + 'px',
    });
  }

  isMobile() {
    return this.site.device !== 'desktop';
  }

  useMobileStyle() {
    // Currently defaulting to desktop style for sizing
    return this.site.useMobileStyle && this.isMobile();
  }

  solve_btn_w() {
    var value = 285 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.3;
    return value;
  }

  btn_holer_tb() {
    var value = 25 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.02;
    return value;
  }

  btns_h() {
    var value = 86 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.09;
    return value;
  }

  overlay_p() {
    var value = 5 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.005;
    return value;
  }

  overlay_points_size() {
    var value = 25 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.03;
    return value;
  }

  overlay_points_leading() {
    var value = 30 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.03;
    return value;
  }

  overlay_sub_size() {
    var value = 44 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.03;
    return value;
  }

  overlay_sub_leading() {
    var value = 46 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.03;
    return value;
  }

  overlay_title_size() {
    var value = 44 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.03;
    return value;
  }

  overlay_title_leading() {
    var value = 46 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.035;
    return value;
  }

  overlay_desc_size() {
    var value = 25 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.02;
    return value;
  }

  overlay_desc_leading() {
    var value = 30 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.0225;
    return value;
  }
  overlay_tb() {
    var value = 10 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0;
    return value;
  }

  overlay_lr() {
    var value = 25 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.05;
    return value;
  }

  overlay_w() {
    var value = 583 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.42;
    return value;
  }

  overlay_h() {
    var value = 520 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.2;
    return value;
  }

  overlay_holder_h() {
    var value = 290 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.2;
    return value;
  }

  overlay_t() {
    var value = 155 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.1;
    return value;
  }

  overlay_l() {
    var value = 220 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.244;
    return value;
  }

  wedge_w() {
    var value = 220 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.15;
    return value;
  }

  wedge_h() {
    var value = (400 / 220) * this.spin_now_w();
    return value;
  }

  wedge_t() {
    var value = 80 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.05;
    return value;
  }

  wedge_l() {
    var value = 105 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.15;
    return value;
  }

  spin_now_w() {
    var value = 632 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.6;
    return value;
  }

  spin_now_h() {
    var value = (100 / 632) * this.spin_now_w();
    return value;
  }

  spin_now_t() {
    var value = 75 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.04;
    return value;
  }

  spin_now_l() {
    var value = 168 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.2;
    return value;
  }

  img_wh() {
    var value = 915 * utils.scale();
    if (this.useMobileStyle())
      value = utils.window_width() * ADJUSTMENT_CONSTS.MOBILE_VIEW_WIDTH;
    return value;
  }

  img_t() {
    var value = 185 * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 0.14;
    return value;
  }

  img_l() {
    var value = 20 * utils.scale();
    if (this.useMobileStyle())
      value = utils.window_width() * ADJUSTMENT_CONSTS.MOBILE_VIEW_PADDING;
    return value;
  }

  width() {
    var value = utils.max_width * utils.scale();
    if (this.useMobileStyle()) value = utils.window_width() * 1;
    return value;
  }

  height() {
    var value = (605 / 961) * this.width();
    if (this.useMobileStyle())
      value = ADJUSTMENT_CONSTS.MOBILE_HEIGHT * this.width();
    return value;
  }
}

export default Wheel;
