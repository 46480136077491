import { AppThunk } from "../actions/actionTypes";

export interface SiteActionType {
  IS_LOADING: string;
  SET_ERROR: string;
  SET_DATA: string;
}

export interface AccountParamsType {
  callback?: any;
}

export type FnType = () => void;
export type FnAnyType = () => any;

export interface ScreenSetType {
  screenSet: string;
  startScreen?: string;
  onAfterSubmit?: FnType;
}

export interface GigyaHandlersType {
  onLogin: FnAnyType;
  onLogout: FnAnyType;
}

export type GigyaFunctionType = (params: AccountParamsType) => void;

export interface GigyaAccountsType {
  getAccountInfo: GigyaFunctionType;
  logout: GigyaFunctionType;
  login: GigyaFunctionType;
  showScreenSet: (params: ScreenSetType) => void;
  addEventHandlers: (params: GigyaHandlersType) => void;
}

export interface GigyaType {
  accounts: GigyaAccountsType;
}

export interface ProfileType {
  email: string;
  firstName: string;
  lastName: string;
}

export interface AuthState {
  gigya: GigyaType | null;
  profile: ProfileType | null;
  uid: string | null;
  isLoggedIn: boolean;
  isLoadingProfile: boolean;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  isRegistering: boolean;
  profileError: boolean;
  loginError: boolean;
  logoutError: boolean;
  registerError: boolean;
  loginStatusChecked: boolean;
}

export interface AnalyticsPageLoadType {
  isLoggedIn: boolean;
  loginStatusChecked: boolean;
  contentTitle?: string;
}

export interface BingoTileType {
  img: string;
  id: string;
}

export interface BingCategoryType {
  category: string;
  required: number;
  tiles: BingoTileType[];
}

export interface BingoGameState {
  tilesCategories: BingCategoryType[];
  freeSpace: BingoTileType | null;
  loadingError: boolean;
  isLoading: boolean;
  isLoaded: boolean;
}

export interface BingoConfigProps {
  introLogo?: string;
  iconVolOn?: string;
  iconVolOff?: string;
  headerLogo?: string;
  backgroundImage?: string;
  winGraphic?: string;
  boardBackgroundColor?: string;
  boardBorderColor?: string;
  winLine?: string;
  tileHighlightColor?: string;
  playBtnText?: string;
  playBtnColor?: string;
  refreshBtnText?: string;
  refreshBtnColor?: string;
  howToPlayBtnText?: string;
  howToPlayBtnColor?: string;
}

export interface BannerAdType {
  image?: string;
  url?: string;
}

export interface SourceType {
  file: string;
}

export interface PlayListType {
  title: string;
  image: string;
  url: string;
}

export interface VideoSliderType {
  playList: PlayListType[];
  onChange: (i: number) => void;
}

export interface VideoType {
  playList: PlayListType[];
  script: string;
  background: string;
  title: string;
  titleColor: string;
  ctaText: string;
  ctaLink: string;
}

export interface VideoGalleryType {
  video: VideoType;
}

export interface PromoUnitType {
  image: string;
  title: string;
  description: string;
  ctaText: string;
  ctaUrl: string;
}

export interface PromoUnitsType {
  promos: PromoUnitType[];
}

export interface InstructionsType {
  onClose: () => void;
  show: boolean;
}

export type ButtonVariantType = "primary" | "purple" | "light" | "secondary";
// TODO: links not explicitly in style guide
export type ButtonDisplayType = "button" | "link";

export interface BonusPuzzleGameType {
  gameID: string;
  hint: string;
  solution: string;
  date: string;
}

export interface BonusPuzzlePageType {
  isLoggedIn: boolean;
  loginStatusChecked: boolean;
  onLogIn: () => AppThunk<void>;
  onJoin: () => AppThunk<void>;
}

export interface BonusPuzzleType {
  isLoggedIn: boolean;
  loginStatusChecked?: boolean;
  freezeGameBoard?: boolean;
  onLogIn: () => AppThunk<void>;
  onJoin: () => AppThunk<void>;
  gameID: string;
  hint: string;
  solution: string;
  date: string;
  background: string;
  title: string;
  titleColor: string;
  startOverLbl: string;
  startOverClass: ButtonVariantType;
  submitLbl: string;
  submitClass: ButtonVariantType;
  learnToPlayLbl: string;
  learnToPlayClass: ButtonVariantType;
  winTitle: string;
  winDescription: string;
  loseTitle: string;
  loseDescription: string;
}

export type BonusPuzzleStatus = "won" | "lost" | "none" | "inprogress";

export interface BonusPuzzleModalType {
  show: boolean;
  title: string;
  description: string;
  onClose: () => void;
  onHide: () => void;
  btnCloseText?: string;
}

export interface BonusPuzzleLoginModalType {
  isLoggedIn: boolean;
  onLogIn: () => AppThunk<void>;
  onJoin: () => AppThunk<void>;
  loginStatusChecked: boolean;
}

export interface TileInputType {
  letter?: string;
  readOnly: boolean;
  focused: boolean;
  onBack: () => void;
  onChange: (s: string) => void;
  onForward: () => void;
  onSumbit: () => void;
  onSetFocus: () => void;
}

export interface BonusPuzzlePageFetchType {
  ad: BannerAdType;
  bonusPuzzle: BonusPuzzleType;
  promoUnits: PromoUnitType[];
  video: VideoType;
  error: boolean;
}

export const google_ad_size = [300, 250];
export const google_ad_unitname = "/306002/wof.com";
