import { combineReducers } from 'redux';
import bingo from './bingo';
import site from './site';
import auth from './auth';

const rootReducer = combineReducers({
  bingo,
  site,
  auth,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
