import {
  LOG_IN_TYPE,
  LOG_OUT_TYPE,
  JOIN_TYPE,
  PROFILE_TYPE,
  SET_GIGYA,
} from '../actions/actionTypes';
import { AuthState } from '../types';

const initialState: AuthState = {
  gigya: null,
  profile: null,
  uid: null,
  isLoggedIn: false,

  isLoadingProfile: false,
  isLoggingIn: false,
  isLoggingOut: false,
  isRegistering: false,

  profileError: false,
  loginError: false,
  logoutError: false,
  registerError: false,

  loginStatusChecked: false,
};

const reducer = (state = initialState, action: any): AuthState => {
  switch (action.type) {
    case LOG_IN_TYPE.SET_ERROR:
      return { ...state, loginError: action.payload };
    case LOG_IN_TYPE.IS_LOADING:
      return { ...state, isLoggingIn: action.payload };
    case LOG_IN_TYPE.SET_DATA:
      return { ...state, isLoggedIn: true, ...action.payload };
    case JOIN_TYPE.SET_ERROR:
      return { ...state, registerError: action.payload };
    case JOIN_TYPE.IS_LOADING:
      return { ...state, isRegistering: action.payload };
    case JOIN_TYPE.SET_DATA:
      return { ...state, isLoggedIn: true, ...action.payload };
    case PROFILE_TYPE.SET_ERROR:
      return {
        ...state,
        loginStatusChecked: true,
        profileError: action.payload,
      };
    case PROFILE_TYPE.IS_LOADING:
      return { ...state, isLoadingProfile: action.payload };
    case PROFILE_TYPE.SET_DATA:
      return { ...state, ...action.payload, loginStatusChecked: true };
    case LOG_OUT_TYPE.SET_ERROR:
      return {
        ...state,
        logoutError: action.payload,
        isLoggedIn: false,
        profile: null,
        uid: null,
      };
    case LOG_OUT_TYPE.IS_LOADING:
      return { ...state, isLoggingOut: action.payload };
    case LOG_OUT_TYPE.SET_DATA:
      return { ...state, isLoggedIn: false, profile: null, uid: null };
    case SET_GIGYA:
      return { ...state, gigya: action.payload };
    default:
      return state;
  }
};

export default reducer;
