// TODO: setup in CloudFront so we can use relative url
// const baseUrl = 'https://dev.qa.wheeloffortune.com/';
const baseUrl = window.location.origin + "/";
// const baseUrl = "https://dev-wheel-of-fortune.pantheonsite.io/";
// const baseUrl = "https://admin.wheeloffortune.com/";
// const baseUrl = "https://stg.wheeloffortune.com/";

export default {
  baseUrl,
  baseApiUrl: `${baseUrl}api/`,
  searchUrl: `${baseUrl}search/node?keys=`,
  endpoints: {
    tossUp: "tossup-challenge-data",
    bingo: "bingo-categories",
    bingoConfig: "bingo-configuration",
    siteData: "site-data",
    nav: "menu_items/main?_format=json",
    footer: "menu_items/footer?_format=json",
    footerMenu: "menu_items/footer-menu?_format=json",
    bonusPuzzle: "bonus-puzzle-data",
    giveaways: "giveaways", // TODO: deloyed route is pending
    featuredGiveaway: "featured-giveaway",
  },
};
