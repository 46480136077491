import { BINGO_TYPE } from '../actions/actionTypes';
import { BingoGameState } from '../types';

const initialState: BingoGameState = {
  isLoading: false,
  isLoaded: false,
  loadingError: false,
  freeSpace: null,
  tilesCategories: [],
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BINGO_TYPE.SET_ERROR:
      return { ...state, loadingError: action.payload };
    case BINGO_TYPE.IS_LOADING:
      return { ...state, isLoading: action.payload };
    case BINGO_TYPE.SET_DATA:
      return { ...state, ...action.payload, isLoaded: true };
    default:
      return state;
  }
};

export default reducer;
