//import TweenMax from '../gsap/TweenMax';
import utils from './utils';

const MPEG_TYPE = 'audio/mpeg';
const OGG_TYPE = 'audio/ogg';

class Audio {
  id = 'audio';
  sounds = [];
  muted = true;
  turn_audio_on = false;
  all_loaded = false;
  active = '';

  initSounds() {
    const createSound = (id, loop = false) => {
      this.sounds.push({
        id,
        loop,
        mp3: require(`../audio/${id}.mp3`),
        ogg: require(`../audio/${id}.ogg`),
        initialized: false,
        playing: false,
        loaded: false,
      });
    };
    createSound('puzzle_reveal');
    createSound('puzzle_reveal_and_toss');
    createSound('toss_up');
    createSound('toss_win_eq');
    createSound('wheel_spin');
    createSound('wof_chant');
    createSound('clock', true);
    createSound('double_buzz');
    createSound('bankrupt');
    createSound('goodie');
  }

  constructor() {
    const _self = this;

    this.initSounds();

    const muted = false;
    // const muted = utils.unpersist_muted();
    this.setMuted(typeof muted === 'boolean' ? muted : false);

    window.onblur = function() {
      if (!_self.muted) {
        _self.toggleMute(true, false);
        _self.turn_audio_on = true;
      }
    };
    window.onfocus = function() {
      if (_self.turn_audio_on) {
        _self.toggleMute(false, false);
      }
      _self.turn_audio_on = false;
    };

    utils.trace(this.id + ' render this.sounds.length = ' + this.sounds.length);
  }

  /**
   * This function should only be called on the first user interaction
   * to preload all of the sounds. This is way to allow sound to be played later on
   * without user interaction (applies mainly to mobile).
   */
  async initializeOnUserInteraction(callback) {
    if (this.all_loaded) {
      callback();
      return;
    }

    this.all_loaded = true;

    this.sounds.forEach(sound => {
      if (!sound.initialized) {
        this.initialize(sound);
      }
    });
    // PLaying audio with no src.
    // This hack allows playing with the src later on without user interaction,
    // as long as this function is called during a user interaction.
    const playPromises = this.sounds.map(sound => sound.obj.play);
    const data = await Promise.all(playPromises);
    callback();
    // Promise.all(playPromises).finally(() => callback());
  }

  initialize(sound) {
    const audio = document.createElement('audio');

    audio.id = sound.id;

    var canPlayType = audio.canPlayType(MPEG_TYPE);

    const canPlayMpeg =
      [true, 'true', 'maybe', 'probably'].findIndex(
        cpt => cpt === canPlayType,
      ) > -1;

    if (canPlayMpeg) {
      audio.type = MPEG_TYPE;
    } else {
      audio.type = OGG_TYPE;
    }

    if (sound.loop) {
      audio.loop = true;
    }

    audio.muted = this.muted;

    sound.obj = audio;
    sound.initialized = true;
  }

  load(id) {
    const sound = this.sounds.find(sound => sound.id === id);

    if (sound) {
      if (!sound.initialized) {
        this.initialize(sound);
      }

      const audio = sound.obj;
      audio.src = audio.type = MPEG_TYPE ? sound.mp3 : sound.ogg;
      sound.loaded = true;
      return true;
    }

    return false;
  }

  /**
   * Plays the audio if it is available.
   * Returns a promise that is activated when the audio starts playing,
   * or fails.
   *
   * @param {string} id
   */
  play(id) {
    this.stopActive();
    const sound = this.sounds.find(sound => sound.id === id);

    if (sound) {
      if (sound.loaded || this.load(id)) {
        sound.playing = true;
        sound.obj.currentTime = 0;
        this.active = id;
        this.setSoundVolume(sound.obj);
        return sound.obj.play();
        // Seems to work without the delay
        //TweenMax.delayedCall(0.01, this.set_current_time, [sound.obj], this);
      }
    }

    // ensure this function is always chainable to a promise
    return Promise.resolve();
  }

  stopActive() {
    const sound = this.sounds.find(sound => sound.id === this.active);
    if (sound) {
      this.stopSound(sound);
    }
  }

  stopSound(sound) {
    sound.obj.pause();
    sound.obj.currentTime = 0;
    sound.playing = false;
    this.active = '';
  }

  stop() {
    this.sounds.forEach(sound => {
      if (sound.playing) {
        this.stopSound(sound);
      }
    });
  }

  setMuted(isMuted) {
    this.muted = isMuted;
  }

  setSoundVolume(sound) {
    sound.muted = this.muted;
    sound.volume = this.muted ? 0 : 1;
  }

  toggleMute(isMuted, persist = true) {
    this.setMuted(isMuted);
    if (persist) {
      utils.persist_muted(isMuted);
    }
    this.sounds.forEach(sound => {
      if (sound.loaded) {
        this.setSoundVolume(sound.obj);
      }
    });
  }
}

export default Audio;
