import React, { useEffect, useState } from "react";
import TossUpChallenge from "../../components/TossUpChallenge";
import BannerAd from "../../components/BannerAd";
import GoogleAd from "../../components/GoogleAd";
import PageMeta from "../../components/PageMeta";
import AnalyticsPageLoad from "../../components/AnalyticsPageLoad/AnalyticsPageLoad";
import { fetchTossUpChallenge } from "../../api/cms";

import "./styles.scss";

const TossUpChallengePage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [basicPageHomeAd, setAd] = useState({});
  const [googleAd, setGoogleAd] = useState({});
  const [pageMeta, setPageMeta] = useState({});
  const [tossUpProps, setTossUpProps] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTossUpChallenge();
      if (data.error === true) {
        return;
      }
      const {
        basicPageHomeAd,
        config,
        page,
        googleAd,
        puzzles: { rows },
      } = data;
      setIsLoaded(true);
      setPageMeta(page);
      setAd(basicPageHomeAd);
      setGoogleAd(googleAd);
      setTossUpProps({ puzzles: rows, config });
    };
    fetchData();
  }, []);

  const { puzzles = null, config = null } = tossUpProps;
  return (
    <div className="">
      <PageMeta {...pageMeta} />
      {isLoaded && (
        <AnalyticsPageLoad contentTitle={config && config.commonHeader} />
      )}
      <TossUpChallenge puzzles={puzzles} config={config} />
      {basicPageHomeAd &&
        Object.keys(basicPageHomeAd).length !== 0 &&
        basicPageHomeAd.image && (
          <BannerAd image={basicPageHomeAd.image} url={basicPageHomeAd.url} />
        )}

      {googleAd.headerScript && googleAd.bodyScript ? (
        <GoogleAd
          ad={googleAd}
          header={googleAd.headerScript}
          body={googleAd.bodyScript}
        />
      ) : null}
    </div>
  );
};

export default TossUpChallengePage;
