import $ from 'jquery';
import { breakpoints } from '../../../../utils';

export const MUTED_KEY = 'muted_key';
export const PUZZLE_KEY = 'puzzle';
export const SCORE_KEY = 'score';
export const REACT_PATH = '/play/toss-up-challenge';

export const MOBILE_VIEW_WIDTH = 0.95;
export const MOBILE_VIEW_PADDING = (1 - MOBILE_VIEW_WIDTH) / 2.0;
// These uses the original magic number of 0.7 for scaling other views
export const MOBILE_WIDTH_SCALE = MOBILE_VIEW_WIDTH / 0.7;
export const MOBILE_HEIGHT = (662 / 1334) * MOBILE_WIDTH_SCALE;
export const MOBILE_HEIGHT_GRID = (632 / 1334) * MOBILE_WIDTH_SCALE;

export const ADJUSTMENT_CONSTS = {
  MOBILE_VIEW_WIDTH,
  MOBILE_VIEW_PADDING,
  MOBILE_WIDTH_SCALE,
  MOBILE_HEIGHT,
  MOBILE_HEIGHT_GRID,
};

const tracking = true;
const max_width = breakpoints.tucMaxWidth;
const mobile_width = breakpoints.md;

const isTouchDevice = 'ontouchstart' in document.documentElement;

const puzzle_override_str = query_string('puzzle_override');
const isPuzzleOveride = puzzle_override_str && !isNaN(+puzzle_override_str);
const debugging = isPuzzleOveride ? true : false;
const puzzle_override = isPuzzleOveride ? +puzzle_override_str : null;

export function trace(val) {
  if (debugging && window.console) console.log(val);
}

export function external_link(href, target, id) {
  track({ id: id, url: href, event: 'external_link' });
  window.open(href, target);
}

function get_data_for_site() {
  const data = {
    is_cms: true,
    puzzle_start_index: null,
    puzzle_start_score: null,
    start_from_games: false,
  };

  const regex = RegExp(`${REACT_PATH}/?$`);
  data.is_cms = !regex.test(window.location.pathname);

  if (!data.is_cms) {
    const originalIndex = parse_string_data_to_int(PUZZLE_KEY);
    const originalScore = parse_string_data_to_int(SCORE_KEY);

    if (originalIndex !== null && originalScore !== null) {
      delete_react_toss_up_data();
      data.puzzle_start_index = originalIndex;
      data.puzzle_start_score = originalScore;
      data.start_from_games = true;
    }
  }

  return data;
}

export function track(options) {
  trace('------------------------------------------');
  trace('track tracking = ' + tracking);
  trace('------------------------------------------');
  if (!tracking) return;

  trace(JSON.stringify(options));

  for (let prop in options) {
    trace('options.' + prop + ' = ' + options[prop]);
  }
}

export function getImage(fileName) {
  let img = '';
  try {
    img = require(`../images/${fileName}`);
  } finally {
    return img;
  }
}

function localStorageSet(key, val) {
  try {
    window.localStorage.setItem(key, val);
  } catch (e) {
    // thrown if disabled or quota exceeded
    console.log(e);
  }
}

function localStorageGet(key) {
  return window.localStorage.getItem(key);
}

function localStorageRemove(key) {
  return window.localStorage.removeItem(key);
}

export function persist_muted(is_muted) {
  localStorageSet(MUTED_KEY, is_muted === true);
}

export function unpersist_muted() {
  // local storage only returns strings
  return JSON.parse(localStorageGet(MUTED_KEY));
}

export function persist_react_toss_up_data(react_puzzle_id, puzzle_score) {
  localStorageSet(PUZZLE_KEY, react_puzzle_id);
  localStorageSet(SCORE_KEY, puzzle_score);
}

export function delete_react_toss_up_data() {
  localStorageRemove(PUZZLE_KEY);
  localStorageRemove(SCORE_KEY);
}

export function parse_string_data_to_int(queryKey) {
  const queryStr = localStorageGet(queryKey);
  const queryInt = +queryStr;
  return queryStr && !isNaN(queryInt) ? queryInt : null;
}

export function parse_string_from_query_to_int(queryKey) {
  const queryStr = query_string(queryKey);
  const queryInt = +queryStr;
  return queryStr && !isNaN(queryInt) ? queryInt : null;
}

export function query_string(name) {
  let match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function thousands_separator(num) {
  let n = num.toString(),
    p = n.indexOf('.');
  return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, function($0, i) {
    return p < 0 || i < p ? $0 + ',' : $0;
  });
}

export function scroll_to(val) {
  trace('scroll_to val = ' + val);
  // TODO: throwing console errors
  // let _position = $(val).position();
  // _position = _position.top;
  // TweenMax.to($('body'), 1, {
  //   delay: 0.5,
  //   scrollTo: { y: _position },
  //   ease: 'Power2.easeOut',
  // });
}

export function pixel_depth() {
  var value = window.screen.pixelDepth;
  return value;
}

export function window_width() {
  let value = Math.round($('#toss_up_container').innerWidth());
  return value;
}

export function window_height() {
  let value = Math.round($('#toss_up_container').innerHeight());
  return value;
}

export function scale() {
  let value = window_width() / max_width;
  if (value > 1) value = 1;
  return value;
}

export function device_detect() {
  const is_iPad = navigator.userAgent.match(/iPad/i) !== null;
  let is_Android_Tablet;

  if (
    navigator.userAgent.match(/Android/i) !== null &&
    navigator.userAgent.match(/Mobile/i) === null
  ) {
    is_Android_Tablet = true;
  } else {
    is_Android_Tablet = false;
  }

  const pixelDepth = pixel_depth();
  const width = window_width();
  const height = window_height();
  const isTouchDevice = 'ontouchstart' in document.documentElement;

  trace('----------------- device_detect');
  trace(
    'pixelDepth = ' +
      pixelDepth +
      ' width = ' +
      width +
      ' height = ' +
      height +
      ' isTouchDevice = ' +
      isTouchDevice,
  );

  if (is_iPad || is_Android_Tablet) {
    trace('mobile');

    return 'mobile';
  }

  if (width < mobile_width) {
    trace('mobile on desktop');

    return 'mobile';
  }

  if (width > mobile_width) {
    trace('desktop, window_width() > mobile_width');

    return 'desktop';
  }

  if (width > mobile_width && pixelDepth === 24) {
    trace('desktop, window_width() > mobile_width && pixelDepth === 24');

    return 'desktop';
  }

  if (pixelDepth === 24 && isTouchDevice === false) {
    trace('desktop, pixelDepth === 24 && isTouchDevice === false');

    return 'desktop';
  }
  if (pixelDepth === 32 && isTouchDevice === true && width >= mobile_width) {
    trace('mobile');

    return 'mobile';
  }
  if (
    pixelDepth === 32 &&
    isTouchDevice === true &&
    (height === 628 || height === 414)
  ) {
    trace('iphone 6+');

    return 'mobile';
  }
  if (pixelDepth === 32 && isTouchDevice === true) {
    trace('mobile');

    return 'mobile';
  }
}

export default {
  max_width,
  isTouchDevice,
  puzzle_override,
  pixel_depth,
  window_height,
  window_width,
  scale,
  track,
  external_link,
  trace,
  getImage,
  persist_react_toss_up_data,
  delete_react_toss_up_data,
  parse_string_data_to_int,
  parse_string_from_query_to_int,
  query_string,
  thousands_separator,
  scroll_to,
  device_detect,
  get_data_for_site,
  persist_muted,
  unpersist_muted,
};
