/**
 * @param path{{ad_image:any,ad_url:any,ad_description:any}}
 */
const getImage = (path) =>
  require(`../../components/TossUpChallenge/code/images/${path}`);

const parseTucData = (json) => {
  const { tossupChallengeTheme, tossupChallengeRows } = json;

  const {
    title,
    metatag_description,
    metatag_image,
    headline,
    subhead,
    description,
    description_font_color,
    header_bg_color,
    header_font_color,
    page_background_image,
    how_to_play_font_color,
    how_to_play_bg_color,
    how_to_play_hover_bg_color,
    header_background_image,
    play_now_font_color,
    play_now_bg_color,
    play_now_hover_bg_color,
    bonus_ad_image,
    bonus_ad_url,
    custom_spin_the_wheel_wedge,
    interstitial_image,
    interstitial_url,
    audio_on_icon,
    audio_off_icon,
    audio_icon_bg_color,
    audio_icon_hover_bg_color,
    tossup_challenge_video,
    basicpageAds,
    gamepageAds,
    wheel_image,
  } = tossupChallengeTheme;

  return {
    page: {
      title: title,
      meta: metatag_description,
      metaImage: metatag_image,
    },
    config: {
      howToPlay: {
        htpCloseText: "Close",
        htpCloseClass: "white_btn",

        button: {
          fontColor: how_to_play_font_color,
          bgColor: how_to_play_bg_color,
          hoverBgColor: how_to_play_hover_bg_color,
        },
      },
      common: {
        backgroundPath: page_background_image,
        commonHeader: headline,
        commonSubheader: subhead,
        commonDescription: description,
        howToPlayText: "How To Play",
        button: {
          fontColor: how_to_play_font_color,
          bgColor: how_to_play_bg_color,
          hoverBgColor: how_to_play_hover_bg_color,
        },
        fontColor: header_font_color,
        descriptionFontColor: description_font_color,
        bgColor: header_bg_color, // TODO: not used with new design
      },
      intro: {
        introPlayNowText: "Play Now",
        introPlayNowClass: "white_btn",
        introPath: header_background_image,
        commonDescription: description,
        descriptionFontColor: description_font_color,

        button: {
          fontColor: play_now_font_color,
          bgColor: play_now_bg_color,
          hoverBgColor: play_now_hover_bg_color,
        },
      },
      game: {
        gameSolveText: "Solve Puzzle",
        gameSolveClass: "white_btn",
        gameSubmitText: "Submit",
        gameSubmitClass: "white_btn",
        gameNextText: "Next Puzzle",
        gameNextClass: "white_btn",
        gameTryAgainText: "Try Again",
        gameTryAgainClass: "white_btn",
        gameSpinText: "Spin for Points",
        gameSpinClass: "white_btn",
        gamePlayAgainText: "Play Again",
        gamePlayAgainClass: "white_btn",
        gameGrid: getImage("game_grid.png"),
        hintBackground: getImage("hint_background.png"),
        hasBonusAd: !!(bonus_ad_image && bonus_ad_url),
        gameBonusAdPath: bonus_ad_image,
        gameBonusAdUrl: bonus_ad_url,

        button: {
          fontColor: play_now_font_color,
          bgColor: play_now_bg_color,
          hoverBgColor: play_now_hover_bg_color,
        },
      },
      wheel: {
        wheelSpinText: "Spin",
        wheelContinueText: "Continue",
        wheelButtonClass: "white_btn",
        wedgePath: custom_spin_the_wheel_wedge,
        wheelPath: wheel_image
          ? wheel_image
          : getImage("wheel/wheel-LITEGAME-TEMPLATE.png"),

        button: {
          fontColor: play_now_font_color,
          bgColor: play_now_bg_color,
          hoverBgColor: play_now_hover_bg_color,
        },
      },
      interstitial: {
        nextRoundText: "Next Round",
        nextRoundClass: "white_btn",
        adProps: {
          img: gamepageAds.bonusAd.ad_image,
          url: gamepageAds.bonusAd.ad_url,
          description: gamepageAds.bonusAd.ad_description,
          target: "_blank",
          headScript:
            gamepageAds.googleAd && gamepageAds.googleAd.headerScript
              ? gamepageAds.googleAd.headerScript
              : "",
          bodyScript:
            gamepageAds.googleAd && gamepageAds.googleAd.bodyScript
              ? gamepageAds.googleAd.bodyScript
              : "",
          height:
            gamepageAds.googleAd && gamepageAds.googleAd.height
              ? gamepageAds.googleAd.height
              : "",
          width:
            gamepageAds.googleAd && gamepageAds.googleAd.width
              ? gamepageAds.googleAd.width
              : "",
        },
        props: {
          img: interstitial_image,
          url: interstitial_url,
          target: "_blank",
        },
        button: {
          fontColor: play_now_font_color,
          bgColor: play_now_bg_color,
          hoverBgColor: play_now_hover_bg_color,
        },
      },
      audio: {
        onIcon: audio_on_icon,
        offIcon: audio_off_icon,
        button: {
          bgColor: audio_icon_bg_color,
          hoverBgColor: audio_icon_hover_bg_color,
        },
      },
    },
    puzzles: {
      rows: tossupChallengeRows,
    },
    ad: {
      image: gamepageAds.bonusAd.ad_image,
      url: gamepageAds.bonusAd.ad_url,
    },
    basicPageHomeAd: {
      image: basicpageAds.bonusAd.ad_image,
      url: basicpageAds.bonusAd.ad_url,
    },
    googleAd: {
      headerScript:
        basicpageAds.googleAd && basicpageAds.googleAd.headerScript
          ? basicpageAds.googleAd.headerScript
          : "",
      bodyScript:
        basicpageAds.googleAd && basicpageAds.googleAd.bodyScript
          ? basicpageAds.googleAd.bodyScript
          : "",
      height:
        basicpageAds.googleAd && basicpageAds.googleAd.height
          ? basicpageAds.googleAd.height
          : "",
      width:
        basicpageAds.googleAd && basicpageAds.googleAd.width
          ? basicpageAds.googleAd.width
          : "",
    },
    video: {
      src: tossup_challenge_video,
    },
  };
};

export { parseTucData };
