import React from "react";
import TucButton from "./TucButton";
import utils from "../code/js/utils";
import { tossUpIds } from "../../../analyticsTags";

const description =
  "Solve the puzzles as fast as you can to win points. The game is made up of three rounds of puzzles, with three puzzles per round. Completing all the rounds will unlock the Bonus Round, where solving the puzzle wins bonus points - and the game!";
const instructions = [
  ["TO PLAY", ""],
  ["1.", " Select PLAY NOW to view the first puzzle."],
  [
    "2.",
    " Letters will reveal on the puzzleboard one by one. When you think you can solve it, select SOLVE. Type your response onto the puzzleboard.",
  ],
  [
    "3.",
    " You will have a short time to enter your puzzle solution. Once entered, click SUBMIT.",
  ],
  [
    "4.",
    " If correct, you can advance to the next puzzle. If incorrect - or you run out of time - you will return to the beginning of the level.",
  ],
  [
    "5.",
    " After all 3 puzzles are solved for a round, you can spin for bonus points and advance to the next level. Once 3 rounds are complete, you'll advance to the Bonus Round to solve the final puzzle and have one last chance to win bonus points.",
  ],
];
const scoring = [
  ["SCORING", ""],
  [
    "●",
    " The time allotted to solve each puzzle is determined by the round and the number of letters in the puzzle.",
  ],
];

const renderLine = (data, key) => (
  <div className="instruct_line" key={key}>
    <strong>{data[0]}</strong>
    {data[1]}
  </div>
);

const HowToPlay = ({
  onClose,
  htpCloseClass,
  htpCloseText,
  isOpen,
  button,
}) => {
  return (
    <div id="how_to_play" className={`hide-able${isOpen ? "" : " hide"}`}>
      <div id="how_to_play_holder">
        <div id="how_to_play_logo">
          <img src={utils.getImage("wof_logo.png")} alt="logo" />
        </div>
        <div id="how_to_play_desc">{description}</div>
        <div id="how_to_play_instructions">
          <div id="how_to_play_to_play">
            {instructions.map((data, i) =>
              renderLine(data, `play_to_play_${i}`)
            )}
          </div>
          <div id="how_to_play_scoring">
            {scoring.map((data, i) => renderLine(data, `play_scoring_${i}`))}
          </div>
        </div>
        <div id="how_to_play_bottom">
          <div id="how_to_play_btn_holder">
            <TucButton
              btnStyle={button}
              id="how_to_play_close_btn"
              css={htpCloseClass}
              onClick={onClose}
              text={htpCloseText}
            />
          </div>
        </div>
      </div>
      <div
        id={tossUpIds.tossUpHowToPlayClose}
        className="how_to_play_close_x"
        onClick={onClose}
      >
        X
      </div>
    </div>
  );
};

export default HowToPlay;
