import React from 'react';
import { Helmet } from 'react-helmet';

const PageMeta = ({ title }) => (
  <Helmet>{title && <title></title>}</Helmet>
);

PageMeta.defaultProps = {
  title: '',
};

export default PageMeta;
