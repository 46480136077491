import React from "react";
import TucButton from "./TucButton";
import { tossUpIds } from "../../../analyticsTags";

export default ({
  commonHeader,
  commonSubheader,
  commonDescription,
  onAudioClick,
  audioOn,
  onHowToPlayClicked,
  howToPlayText,
  gameInProgress,
  puzzleTimeTxt,
  redAlert,
  button,
  audioProps,
  fontColor,
  descriptionFontColor,
}) => {
  const { onIcon, offIcon, button: audioButton } = audioProps;
  console.log("font color", descriptionFontColor);
  return (
    <div className="game-top">
      {descriptionFontColor ? (
        <style
          dangerouslySetInnerHTML={{
            __html:
              `.game-desc p { color: ` +
              descriptionFontColor +
              ` !important;} `,
          }}
        ></style>
      ) : null}
      <div
        className={`game-header${gameInProgress ? " game-in-progress" : ""}`}
      >
        <h1
          className="game-subtitle"
          style={{ color: fontColor ? fontColor : null }}
        >
          {commonHeader}
        </h1>
        <h1
          className="game-title"
          style={{ color: fontColor ? fontColor : null }}
        >
          {commonSubheader}
        </h1>
        <div
          className="game-desc"
          style={{ color: descriptionFontColor ? descriptionFontColor : null }}
          dangerouslySetInnerHTML={{ __html: commonDescription }}
        ></div>
        <div className="game-top-buttons">
          <TucButton
            id={tossUpIds.tossUpHowToPlayOpen}
            btnStyle={button}
            css="blue_btn how-to-play-btn"
            onClick={onHowToPlayClicked}
            text={howToPlayText}
          />
          <TucButton
            id={tossUpIds.tossUpAudioButton}
            btnStyle={audioButton}
            css="blue_btn game-audio"
            onClick={onAudioClick}
            isCore={false}
          >
            <div className={audioOn ? "on" : "off"}>
              {onIcon && <img src={onIcon} alt="sound on" />}
            </div>
            <div className={audioOn ? "off" : "on"}>
              {offIcon && <img src={offIcon} alt="sound off" />}
            </div>
          </TucButton>
          <div
            id="game_2timer"
            className={`game-timer${redAlert ? " red-alert" : ""}`}
          >
            {puzzleTimeTxt}
          </div>
        </div>
      </div>
    </div>
  );
};
