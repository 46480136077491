import mockBingoData from './mock/data/bingo';
import { parseTucData } from './parse';
import url from './url';

const { endpoints, baseApiUrl: baseUrl } = url;

const fetchCms = async endpoint => {
  try {
    const response = await fetch(`${baseUrl}${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const json = await response.json();

      return json;
    } else {
      throw response.statusText;
    }
  } catch (e) {
    throw e;
  }
};

const postCms = async (endpoint, data) => {
  // TODO:
  try {
    const response = await fetch(`${baseUrl}${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const json = await response.json();

      return json;
    } else {
      throw response.statusText;
    }
  } catch (e) {
    throw e;
  }
};

const postApi = async (endpoint, data) => {
  try {
    const response = await fetch(
      `https://d14m9t9eacgi00.cloudfront.net/api/${endpoint}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
      },
    );

    if (response.ok) {
      const json = await response.json();

      return json;
    } else {
      throw response.statusText;
    }
  } catch (e) {
    throw e;
  }
};

const fetchBingoTiles = async () => {
  try {
    const json = await fetchCms(endpoints.bingo);

    const freeSpace = {
      id: 'free-space',
      img: require('./mock/data/bingo/img/tiles/freespace.png'),
    };

    return {
      freeSpace: json.freeSpaceImage.img ? json.freeSpaceImage : freeSpace,
      tilesCategories: json.tileCategories,
    };
  } catch (e) {
    console.log(e);
    // backup tiles in case of error retrieving tiles
    return mockBingoData();
  }
};

const fetchBingoConfig = async () => {
  try {
    const json = await fetchCms(endpoints.bingoConfig);

    return json;
  } catch (e) {
    console.log(e); // TODO: server error page
    return {};
  }
};

const fetchTossUpChallenge = async () => {
  try {
    const json = await fetchCms(endpoints.tossUp);

    return parseTucData(json);
  } catch (e) {
    console.log(e);
    return { error: true };
  }
};

const fetchNavigation = async () => {
  try {
    const json = await fetchCms(endpoints.nav);

    return json;
  } catch (e) {
    console.log(e);
    return {};
  }
};

const fetchSiteData = async () => {
  try {
    const json = await fetchCms(endpoints.siteData);

    return json;
  } catch (e) {
    console.log(e);
    return {};
  }
};

const fetchFooterButtons = async () => {
  try {
    const json = await fetchCms(endpoints.footer);

    return json;
  } catch (e) {
    console.log(e);
    return {};
  }
};

const fetchFooterLinks = async () => {
  try {
    const json = await fetchCms(endpoints.footerMenu);

    return json;
  } catch (e) {
    console.log(e);
    return {};
  }
};

const fetchBonusPuzzle = async () => {
  try {
    const json = await fetchCms(endpoints.bonusPuzzle);

    return json;
  } catch (e) {
    console.log(e);
    return { error: e };
  }
};

export const fetchGiveaway = async id => {
  try {
    const json = await fetchCms(`${endpoints.featuredGiveaway}/${id}`);
    return json;
  } catch (e) {
    // console.log(e);
    return { error: e };
  }
};

export const fetchGiveawayConfirm = async id => {
  try {
    const json = await fetchCms(`${endpoints.featuredGiveaway}/confirm/${id}`);
    return json;
  } catch (e) {
    // console.log(e);
    return { error: e };
  }
};

const postBonusPuzzleResult = async data => {
  try {
    // TODO: v1.1
    const json = await postCms(endpoints.bonusPuzzle, data);

    return json;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const postGiveawaySignup = async data => {
  try {
    const json = await postApi('giveaway_signup', data);

    return json;
  } catch (e) {
    return null;
  }
};

export {
  fetchBingoTiles,
  fetchBingoConfig,
  fetchTossUpChallenge,
  fetchNavigation,
  fetchSiteData,
  fetchFooterButtons,
  fetchFooterLinks,
  fetchBonusPuzzle,
  postBonusPuzzleResult,
  postGiveawaySignup,
};

export default {
  fetchBingoTiles,
  fetchBingoConfig,
  fetchTossUpChallenge,
  fetchNavigation,
  fetchSiteData,
  fetchFooterButtons,
  fetchFooterLinks,
  fetchBonusPuzzle,
  postGiveawaySignup,
};
