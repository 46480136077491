import capitalize from 'lodash/capitalize';

const toLower = str => (str ? str.toLowerCase() : '');
const pathArray = window.location.pathname.split('/');
const page = pathArray.length > 1 ? pathArray[1] || 'home' : 'home';
const subpage = pathArray.length > 2 ? pathArray[2] || '' : '';
const pageCap = (str, del = '-') =>
  str
    .split(del)
    .map(s => capitalize(s))
    .join('_');

const composeLoginStatus = isLoggedIn =>
  isLoggedIn ? 'Logged_In' : 'Logged_Out';
const hasDataLayer = () =>
  window.dataLayer && typeof window.dataLayer.push === 'function';

const toLowerSnakeCase = id => toLower(id).replace(/ /g, '_');

export const analyticsPageLoad = (isLoggedIn, contentTitle) => {
  // Only call after you have login status
  // This attempts to follow the current pattern of the site:
  // {
  //    login_status: 'Logged_Out',
  //    site_section: 'WOF:Play_Anywhere',
  //    page_title: 'WOF:Play_Anywhere:Ways_to_Play',
  //    content_category: null,
  //    content_title: null,
  // }
  const Page = pageCap(page);
  const Subpage = pageCap(subpage);

  if (hasDataLayer()) {
    window.dataLayer.push({
      login_status: composeLoginStatus(isLoggedIn),
      site_section: `WOF:${Page}`,
      page_title: `WOF:${Page}:${Subpage}`,
      content_category: page,
      content_title: pageCap(contentTitle || '', ' '),
    });
  }
};

export const pageIds = {
  bannerAd: `${page}_banner_ad`,
};

export const bannerAdIds = {
  bannerAd: `${page}_banner_ad`,
};

export const bingoIds = {
  bingoPlayNow: 'bingo_play_now',
  bingoShowInstructions: 'bingo_show_instructions',
  bingoCloseInstructions: 'bingo_close_instructions',
  bingoVolumeToggle: 'bingo_volume_toggle',
  bingoFacebookShare: 'bingo_facebook_share',
  bingoTwitterShare: 'bingo_twitter_share',
  computed: {
    bingoTile: id => `bingo_tile_${toLowerSnakeCase(id)}`,
  },
};

export const bonusPuzzleIds = {
  bonusPuzzleAttemptModalClose: 'bonus_puzzle_attempt_modal_close',
  bonusPuzzleJoin: 'bonus_puzzle_join',
  bonusPuzzleLogin: 'bonus_puzzle_login',
  bonusPuzzleSubmit: 'bonus_puzzle_submit',
  bonusPuzzleShowInstructions: 'bonus_puzzle_show_instructions',
  bonusPuzzleCloseInstructions: 'bonus_puzzle_close_instructions',
};

export const navigationIds = {
  navigationHome: `${page}_navigation_home`,
  navigationJoin: `${page}_navigation_join`,
  navigationLogin: `${page}_navigation_login`,
  navigationLogout: `${page}_navigation_logout`,
  navigationSearch: `${page}_navigation_search`,
  navigationMobileToggle: `${page}_navigation_mobile_toggle_open`,
  computed: {
    navigationDropdown: id => `${page}_navigation_dropdown_${toLower(id)}`,
    navigationDropdownItem: (parentId, id) =>
      `${page}_navigation_dropdown_item_${toLower(parentId)}_${toLower(id)}`,
  },
};

export const tossUpIds = {
  tossUpGameButton: 'toss_up_game_button',
  tossUpAudioButton: 'toss_up_audio_button',
  tossUpHowToPlayOpen: 'toss_up_how_to_play_open',
  tossUpHowToPlayClose: 'toss_up_how_to_play_close',
  tossUpWheelImg: 'toss_up_challenge_wheel_img',
  tossUpWheelSpinButton: 'toss_up_challenge_spin_button',
  tossUpInterstitial: 'toss_up_challenge_interstitial',
  tossUpBonusAd: 'toss_up_challenge_bonus_ad',
};

export const footerIds = {
  footerSonyLogo: 'sony_logo',
  computed: {
    footerNavButton: title => `footer_nav_button_${title}`,
    footerNavLink: title => `footer_nav_link_${title}`,
  },
};

export const socialLinkIds = {
  computed: {
    socialLink: title => `social_link_${toLowerSnakeCase(title)}`,
  },
};

export const videoGalleryIds = {
  cta: 'video_gallery_cta',
};
